import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d13d4b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = { class: "bg-gray-100/50 h-5 text-center text-black relative" }
const _hoisted_3 = {
  key: 0,
  class: "absolute left-2 top-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass(["m-2 p-1 group relative", {
    'cursor-move': !this.ctrlKeyPressed,
    'cursor-context-menu': this.ctrlKeyPressed && (this.isCompatibleInGroup || !this.isAtLeastOneInGroup),
    'cursor-not-allowed': !this.positions.isMooving && !this.isCompatibleInGroup && this.ctrlKeyPressed && this.isAtLeastOneInGroup,
    'gradient-border': this.selectedGroup,
    'opacity-20': this.isAtLeastOneInGroup && !this.isCompatibleInGroup && !this.positions.isMooving,
    'z-20': this.positions.isMooving,
    // Because we have to know what div is behind on move
    'pointer-events-none': this.positions.isMooving || (this.groupIsMooving && this.isInGroup),
  }])
  }, [
    _createElementVNode("div", {
      onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragMouseDown && _ctx.dragMouseDown(...args))),
      class: _normalizeClass(["w-44 text-white text-sm", {'animate-pulse': this.positions.isMooving || (this.groupIsMooving && this.isInGroup),
              [_ctx.backgroundColor]: true,
  }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            class: _normalizeClass([{[_ctx.getColorLevel(_ctx.routing.Rank?.Level ?? 0)]: true}, "text-white rounded px-1 text-sm"])
          }, _toDisplayString(_ctx.routing.Rank?.Rank ?? 'Regrouped'), 3)
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.TimeHelper.formattingMinuteToHoursMinute(_ctx.routing.getCurrentWeekMinutesDefined())) + "h / " + _toDisplayString(_ctx.totalManuTime) + "h ", 1),
        (_ctx.routing.isReplanned)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " 📝 "))
          : _createCommentVNode("", true)
      ])
    ], 34)
  ], 2))
}