
import { defineComponent, PropType } from 'vue';
import RankRouting from '@/class/RankRouting';
import WeeklyPlanningMoveEvent from '@/bus/weeklyPlanningMoveEvent';
import CostCenterHelper from '@/helper/CostCenterHelper';
import GroupedRouting from '@/class/GroupedRouting';
import BaseRouting from '@/class/BaseRouting';
import RankHelper from '@/helper/RankHelper';
import HebdoGroupStore from '@/store/HebdoGroupStore';
import { mapState } from 'pinia';
import DateHelper from '@/helper/DateHelper';
import HebdoViewStore from '@/store/HebdoViewStore';
import TimeHelper from '@/helper/TimeHelper';

export default defineComponent({
  name: 'WPRouting',
  components: {
  },
  props: {
    routing: {
      type: Object as PropType<BaseRouting>,
      required: true,
    },
  },
  computed: {
    TimeHelper() {
      return TimeHelper;
    },
    ...mapState(HebdoViewStore, ['filter']),
    ...mapState(HebdoGroupStore, ['group', 'groupIsMooving']),
    selectedGroup() {
      return this.group.find((r) => r.routing.IdRouting === this.routing.IdRouting) !== undefined;
    },
    backgroundColor() {
      return CostCenterHelper.getBackgroundColorFromGroup(
        this.routing instanceof RankRouting ? this.routing.CostCenterObject?.Group ?? ''
          : (this.routing as GroupedRouting).getGroup() ?? '',
      );
    },
    totalManuTime() {
      return this.routing instanceof RankRouting
        ? TimeHelper.fixFormatHoursMinute(this.routing.getManuTimeWithCoef())
        : TimeHelper.fixFormatHoursMinute(this.routing.ManuTime);
    },
    label() {
      return this.routing instanceof RankRouting
        ? this.routing.Designation1
        : (this.routing as GroupedRouting).getGroup();
    },
    isAtLeastOneInGroup() {
      return this.group.length > 0;
    },
    isInGroup() {
      return this.group.find((p) => p.routing.IdRouting === this.routing.IdRouting) !== undefined;
    },
    isCompatibleInGroup() {
      if (this.isInGroup) return true;
      const startDate = new Date(this.filter.week);
      let allowedDays = new Array<number>();

      this.group.forEach((p) => {
        const routingIdDays = new Array<number>();
        for (let i = 0; i < DateHelper.getBusinessDatesCount(p.routing.startDate, p.routing.endDate); i += 1) {
          const testDate = DateHelper.addDays(new Date(p.routing.getStartDate()), i);
          routingIdDays.push(DateHelper.getBusinessDatesCount(startDate, testDate));
        }

        if (allowedDays.length === 0) {
          allowedDays.push(...routingIdDays);
        } else {
          allowedDays = allowedDays.filter((currentId) => routingIdDays.includes(currentId));
        }
      });
      for (let i = 0; i < DateHelper.getBusinessDatesCount(this.routing.startDate, this.routing.endDate); i += 1) {
        const testDate = DateHelper.addDays(new Date(this.routing.getStartDate()), i);
        if (allowedDays.includes(DateHelper.getBusinessDatesCount(startDate, testDate))) {
          return true;
        }
      }
      return false;
    },
  },
  data() {
    return {
      positions: {
        isMooving: false,
      },
      ctrlKeyPressed: false,
      ctrlKeyCode: '',
      heightSize: 96,
    };
  },
  methods: {
    keydown(e: KeyboardEvent) {
      if (e.ctrlKey || e.altKey) {
        this.ctrlKeyPressed = true;
        this.ctrlKeyCode = e.code;
      }
    },
    keyup(e: KeyboardEvent) {
      if (e.code === this.ctrlKeyCode) {
        this.ctrlKeyCode = '';
        this.ctrlKeyPressed = false;
      }
    },
    getColorLevel(level: number) {
      return RankHelper.getColorLevel(level);
    },
    groupDrag(event: MouseEvent) {
      event.preventDefault();
      WeeklyPlanningMoveEvent.trigger('beforeGroupDrag', { event, routing: undefined });

      if (
        event.clientX < 1
        || event.clientX > document.body.clientWidth
        || event.clientY < 1
        || event.clientY > document.body.clientHeight
      ) {
        return;
      }

      Object.entries(this.group).forEach((arr) => {
        const index = parseInt(arr[0], 10);
        const value = arr[1];

        value.ref.style.top = `${event.clientY - 40 + window.scrollY + (index * 8)}px`;
        value.ref.style.left = `${event.clientX - 62 + (index * 8)}px`;
      });

      WeeklyPlanningMoveEvent.trigger('afterGroupDrag', { event, routing: undefined });
    },
    closeDragGroup(event: MouseEvent) {
      WeeklyPlanningMoveEvent.trigger('beforeGroupMouseUp', { event, routing: undefined });

      HebdoGroupStore().groupIsMooving = false;

      Object.entries(this.group).forEach((arr) => {
        const value = arr[1];
        value.ref.style.position = '';
        value.ref.style.top = '';
        value.ref.style.left = '';
        value.ref.style.zIndex = '';
      });

      document.onmouseup = null;
      document.onmousemove = null;
      WeeklyPlanningMoveEvent.trigger('afterGroupMouseUp', { event, routing: undefined });
    },
    startMoveGroup(event: MouseEvent) {
      WeeklyPlanningMoveEvent.trigger('beforeGroupMouseDown', { event, routing: undefined });

      HebdoGroupStore().groupIsMooving = true;
      Object.entries(this.group).forEach((arr) => {
        const index = parseInt(arr[0], 10);
        const value = arr[1];
        value.ref.style.position = 'absolute';
        value.ref.style.zIndex = String(20 + index);
        value.ref.style.left = `${event.clientX - 62 + (index * 8)}px`;
        value.ref.style.top = `${event.clientY - 40 + window.scrollY + (index * 8)}px`;
      });
      document.onmousemove = this.groupDrag;
      document.onmouseup = this.closeDragGroup;
      WeeklyPlanningMoveEvent.trigger('afterGroupMouseDown', { event, routing: undefined });
    },
    dragMouseDown(event: MouseEvent) {
      event.preventDefault();

      // Group selector
      if (this.ctrlKeyPressed) {
        if (!this.isCompatibleInGroup && this.isAtLeastOneInGroup) {
          return;
        }
        if (this.selectedGroup) {
          this.removeSelfFromGroup();
        } else {
          this.group.push({ routing: this.routing, ref: this.$refs.container as HTMLDivElement });
        }
        return;
      }

      // Group mooving
      if (this.isInGroup) {
        this.startMoveGroup(event);
        return;
      }

      WeeklyPlanningMoveEvent.trigger('beforeMouseDown', { event, routing: this.routing });
      this.positions.isMooving = true;
      // get the mouse cursor position at startup:
      const draggableContainer: HTMLDivElement = this.$refs.container as HTMLDivElement;
      draggableContainer.style.position = 'absolute';

      draggableContainer.style.left = `${event.clientX - 62}px`;
      draggableContainer.style.top = `${event.clientY - 40 + window.scrollY}px`;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
      WeeklyPlanningMoveEvent.trigger('afterMouseDown', { event, routing: this.routing });
    },
    elementDrag(event: MouseEvent) {
      event.preventDefault();
      WeeklyPlanningMoveEvent.trigger('beforeDrag', { event, routing: this.routing });

      if (
        event.clientX < 1
        || event.clientX > document.body.clientWidth
        || event.clientY < 1
        || event.clientY > document.body.clientHeight
      ) {
        return;
      }
      const draggableContainer: HTMLDivElement = this.$refs.container as HTMLDivElement;

      // set the element's new position:
      draggableContainer.style.top = `${event.clientY - 40 + window.scrollY}px`;
      draggableContainer.style.left = `${event.clientX - 62}px`;

      WeeklyPlanningMoveEvent.trigger('afterDrag', { event, routing: this.routing });
    },
    removeSelfFromGroup() {
      HebdoGroupStore().group = this.group
        .filter((r) => r.routing.IdRouting !== this.routing.IdRouting);
    },
    closeDragElement(event: MouseEvent) {
      WeeklyPlanningMoveEvent.trigger('beforeMouseUp', { event, routing: this.routing });
      const draggableContainer: HTMLDivElement = this.$refs.container as HTMLDivElement;
      this.positions.isMooving = false;
      draggableContainer.style.position = '';
      draggableContainer.style.top = '';
      draggableContainer.style.left = '';
      document.onmouseup = null;
      document.onmousemove = null;
      WeeklyPlanningMoveEvent.trigger('afterMouseUp', { event, routing: this.routing });
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('keyup', this.keyup);
  },
  unmounted() {
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('keyup', this.keyup);

    if (this.isInGroup) {
      HebdoGroupStore().group.splice(0);
    }
  },
});
