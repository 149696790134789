import RankRouting from '@/class/RankRouting';
import AffaireRank from '@/class/AffaireRank';
import AffaireStore from '@/store/AffaireStore';
import DateHelper from '@/helper/DateHelper';
import CostCenterHelper from '@/helper/CostCenterHelper';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';
import BaseRouting from '@/class/BaseRouting';
import GroupedRouting from '@/class/GroupedRouting';
import CostCenter from '@/class/CostCenter';

class RoutingHelper {
  public static isInstanceOfGrouped(baseRouting: BaseRouting) {
    return baseRouting instanceof GroupedRouting;
  }

  private static getRoutingsInRankInDay(date: Date, rank: AffaireRank): Array<RankRouting> {
    const routings = new Array<RankRouting>();
    rank.Postes.forEach((poste) => {
      const startDate = new Date(poste.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(poste.endDate);
      endDate.setHours(23, 59, 59, 999);
      if (startDate <= date && endDate >= date) {
        routings.push(poste);
      }
    });

    // recursivity
    rank.Childs.forEach((rankChild) => {
      routings.push(...RoutingHelper.getRoutingsInRankInDay(date, rankChild));
    });

    return routings;
  }

  public static getRoutingsInDay(date: Date): Array<RankRouting> {
    const affaireStore = AffaireStore();
    const routings = new Array<RankRouting>();
    affaireStore.affaires.forEach((affaire) => {
      affaire.Ranks.forEach((rank) => {
        routings.push(...RoutingHelper.getRoutingsInRankInDay(date, rank as AffaireRank));
      });
    });
    return routings;
  }

  private static getRoutingsInRankInRangeDate(minDate: Date, maxDate: Date, rank: AffaireRank)
    : Array<RankRouting> {
    const routings = new Array<RankRouting>();
    rank.getRoutingsToShow().forEach((routing) => {
      let shouldAdd = false;

      if (routing.startDate <= minDate && routing.startDate >= maxDate) {
        shouldAdd = true;
      }

      if (routing.endDate >= minDate && routing.endDate <= maxDate) {
        shouldAdd = true;
      }

      if (routing.startDate <= minDate && routing.endDate >= maxDate) {
        shouldAdd = true;
      }

      if (routing.startDate >= minDate && routing.startDate <= maxDate) {
        shouldAdd = true;
      }

      if (routing.endDate >= minDate && routing.endDate <= maxDate) {
        shouldAdd = true;
      }
      if (shouldAdd) {
        routings.push(routing);
      }
    });

    // Recursivity
    rank.Childs.forEach((rankChild) => {
      routings.push(...RoutingHelper.getRoutingsInRankInRangeDate(minDate, maxDate, rankChild));
    });

    return routings;
  }

  private static getGroupedRoutingsInRangeDate(minDate: Date, maxDate: Date)
    : Array<GroupedRouting> {
    const routings = new Array<GroupedRouting>();
    AffaireStore().affaires.forEach((a) => {
      a.regroupedRoutings.forEach((gr) => {
        let shouldAdd = false;

        if (gr.startDate <= minDate && gr.startDate >= maxDate) {
          shouldAdd = true;
        }

        if (gr.endDate >= minDate && gr.endDate <= maxDate) {
          shouldAdd = true;
        }

        if (gr.startDate <= minDate && gr.endDate >= maxDate) {
          shouldAdd = true;
        }

        if (gr.startDate >= minDate && gr.startDate <= maxDate) {
          shouldAdd = true;
        }

        if (gr.endDate >= minDate && gr.endDate <= maxDate) {
          shouldAdd = true;
        }
        if (shouldAdd) {
          routings.push(gr as GroupedRouting);
        }
      });
    });

    return routings;
  }

  public static getRoutingsInRangeDate(minDate: Date, maxDate: Date): Array<RankRouting> {
    const routings = new Array<RankRouting>();
    const affaireStore = AffaireStore();
    const minDateFormatted = new Date(minDate);
    const maxDateFormatted = new Date(maxDate);
    minDateFormatted.setHours(0, 0, 0, 0);
    maxDateFormatted.setHours(23, 59, 59, 0);

    affaireStore.affaires.forEach((affaire) => {
      affaire.Ranks.forEach((rank) => {
        routings.push(...RoutingHelper.getRoutingsInRankInRangeDate(
          minDateFormatted,
          maxDateFormatted,
          rank as AffaireRank,
        ));
      });
    });
    return routings;
  }

  private static getRoutingInRank(idRouting: number, rank: AffaireRank): RankRouting | undefined {
    let shouldSearchMore = true;
    let find = undefined as undefined | RankRouting;

    rank.Postes.forEach((poste) => {
      if (shouldSearchMore) {
        if (poste.IdRouting === idRouting) {
          find = poste;
          shouldSearchMore = false;
        }
      }
    });

    // Recursivity
    if (shouldSearchMore) {
      rank.Childs.forEach((rankChild) => {
        if (shouldSearchMore) {
          find = this.getRoutingInRank(idRouting, rankChild);
          if (find !== undefined) {
            shouldSearchMore = false;
          }
        }
      });
    }
    return find;
  }

  public static getRoutingById(idRouting: number) : RankRouting | undefined {
    const affaireStore = AffaireStore();
    let shouldSearchMore = true;
    let find = undefined as undefined | RankRouting;
    affaireStore.affaires.forEach((affaire) => {
      if (shouldSearchMore) {
        affaire.Ranks.forEach((rank) => {
          if (shouldSearchMore) {
            const routing = this.getRoutingInRank(idRouting, rank as AffaireRank);
            if (routing !== undefined) {
              shouldSearchMore = false;
              find = routing;
            }
          }
        });
      }
    });
    return find;
  }

  public static getRoutingByWeekAndCostCenter(date: Date, costCenterGroup: string)
    : Array<BaseRouting> {
    const monday = DateHelper.setToMonday(new Date(date));
    monday.setHours(0, 0, 0, 0);
    const sunday = new Date(monday);
    sunday.setHours(24 * (7 - 1));
    sunday.setHours(23, 59, 59, 0);

    // Si le groupe est un GroupedRouting
    if (Object.keys(CostCenter.regroupedGroupId).includes(costCenterGroup)) {
      return RoutingHelper.getGroupedRoutingsInRangeDate(monday, sunday)
        .filter((gr) => gr.getGroup() === costCenterGroup);
    }

    let routings = RoutingHelper.getRoutingsInRangeDate(monday, sunday);
    routings = routings.filter(
      (r) => CostCenterHelper.getCostCenterGroup(r.CostCenter) === costCenterGroup,
    );
    return routings;
  }

  public static getRoutingByWeek(date: Date)
    : Array<BaseRouting> {
    const monday = DateHelper.setToMonday(new Date(date));
    monday.setHours(0, 0, 0, 0);
    const sunday = new Date(monday);
    sunday.setHours(24 * (7 - 1));
    sunday.setHours(23, 59, 59, 0);

    const routings = RoutingHelper.getRoutingsInRangeDate(monday, sunday);
    const groupRoutings = RoutingHelper.getGroupedRoutingsInRangeDate(monday, sunday);

    Array.prototype.push.apply(routings, groupRoutings);
    return routings;
  }

  public static deleteDayRouting(rdp: RoutingDayPlanning) {
    rdp.deleteToApi();
    rdp.weekPlanning.routingsDayPlanning.splice(
      rdp.weekPlanning.routingsDayPlanning.indexOf(rdp),
      1,
    );
  }
}

export default RoutingHelper;
