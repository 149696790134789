
import { ComponentPublicInstance, defineComponent, PropType } from 'vue';
import Affaire from '@/class/Affaire';
import SideBarAffaire from '@/components/gantt-shared/sidebar/SideBarAffaire.vue';
import TimelineSummary
  from '@/components/gantt-shared/content-header/timelines/TimelineSummary.vue';
import CostCenterHelper from '@/helper/CostCenterHelper';
import TimelineCostCenter
  from '@/components/gantt-shared/content-header/timelines/TimelineCostCenter.vue';
import TimelineDeadline
  from '@/components/gantt-shared/content-header/timelines/TimelineDeadline.vue';

export default defineComponent({
  name: 'GanttContentHeader',
  components: { TimelineDeadline, TimelineCostCenter, TimelineSummary },
  props: {
    affaire: {
      type: Affaire,
      required: true,
    },
    ganttWidth: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      require: true,
    },
    sidebarDiv: {
      type: Object as PropType<ComponentPublicInstance<typeof SideBarAffaire>>,
      required: true,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  data() {
    return {
      offsetTop: 0,
    };
  },
  computed: {
    costCenterGroupInJob() {
      return CostCenterHelper.getCostCenterGroupInJob(this.affaire);
    },
  },
  methods: {
    updateOffsetPosition() {
      this.offsetTop = this.sidebarDiv.$el.offsetTop;
    },
  },
  mounted() {
    this.updateOffsetPosition();
  },
  unmounted() {
    this.updateOffsetPosition();
  },
});
