import { defineStore } from 'pinia';
import DateHelper from '@/helper/DateHelper';
import CostCenter from '@/class/CostCenter';

const HebdoViewStore = defineStore('hebdoViewStore', {
  state: () => ({
    filter: {
      week: DateHelper.setToMonday(new Date()),
      costCenter: [CostCenter.getGroups()[0]],
    },
  }),
});

export default HebdoViewStore;
