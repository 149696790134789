
import { defineComponent, PropType, ref } from 'vue';
import AffaireRank from '@/class/AffaireRank';
import GanttValueParentTimeline
  from '@/components/gantt-shared/content/GanttValueParentTimeline.vue';
import Affaire from '@/class/Affaire';
import GanttValuePoste from '@/components/gantt-shared/content/GanttValuePoste.vue';
import deadlineUpdateBus from '@/bus/deadlineUpdateBus';
import moment from 'moment';

export default defineComponent({
  name: 'GanttValueTask',
  components: {
    GanttValueParentTimeline,
    GanttValuePoste,
  },
  props: {
    viewType: {
      type: String,
      required: true,
    },
    shouldShow: {
      type: Boolean,
      default: () => false,
    },
    task: {
      type: AffaireRank,
      required: false,
    },
    jobGroupedRouting: {
      type: Affaire,
      required: false,
    },
    ganttWidth: {
      type: Number,
      default: () => 0,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    endDateGantt: {
      type: Date,
      required: true,
    },
    refMenu: {
      type: Object as PropType<HTMLDivElement>,
      required: true,
    },
    oldNbIndex: {
      type: Number,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  data() {
    return {
      offsetTop: 0,
      height: 0,
      tassk: this.task,
      oldTask: this.task,
      bus: ref(new Map()),
    };
  },
  methods: {
    // Because Vuejs can't watch HTMLDivElement object
    updateOffsetPosition() {
      this.height = this.refMenu.offsetHeight;
      this.offsetTop = this.refMenu.offsetTop;
    },
    tasksGroupedFilteredWeekAndCostCenter() {
      if (this.jobGroupedRouting !== undefined) {
        return this.jobGroupedRouting.getFilteredGroupRoutines();
      }
      return false;
    },
    showInitDeadLine(jobId: number) {
      if (this.jobGroupedRouting?.Job === jobId) {
        const maxDateGrouped = moment(this.jobGroupedRouting?.getMaxDateRouting());
        const maxRankDate = this.jobGroupedRouting.Ranks.reduce((acc, rank) => {
          const maxDateChild = moment(rank.getMaxDateInChildrens());
          const maxDateParent = moment(rank.getMaxDate());
          let maxDate = moment();

          if (rank.getMaxDateInChildrens() === undefined && rank.getMaxDate() === undefined) {
            return acc;
          }

          if (rank.getMaxDateInChildrens() !== undefined && rank.getMaxDate() === undefined) {
            maxDate = maxDateChild;
          } else if (rank.getMaxDateInChildrens() === undefined && rank.getMaxDate() !== undefined) {
            maxDate = maxDateParent;
          } else if (maxDateChild.isAfter(maxDateParent)) {
            maxDate = maxDateChild;
          } else {
            maxDate = maxDateParent;
          }

          if (maxDate.isAfter(acc)) {
            return maxDate;
          }

          return acc;
        }, moment('2000-01-01'));

        const deadline = (maxDateGrouped.isAfter(maxRankDate)) ? maxDateGrouped : maxRankDate;
        deadline.add(1, 'week');

        this.jobGroupedRouting.setDeadline(deadline.format('YYYY-MM-DD'));
      }
    },
  },
  mounted() {
    deadlineUpdateBus.on('update', this.showInitDeadLine);
  },
  unmounted() {
    deadlineUpdateBus.off('update', this.showInitDeadLine);
  },
});
