
import Notiflix from 'notiflix';
import { defineComponent } from 'vue';
import GuiButtonPrimary from '@/components/gui/ButtonPrimary.vue';
import GuiInputText from '@/components/gui/InputText.vue';
import ListeAffaires from '@/components/searchAffaire/ListeAffaires.vue';
import GuiH1 from '@/components/gui/H1.vue';
import ResultatAffaires from '@/components/searchAffaire/ResultatAffaire.vue';
import { DatePicker } from 'v-calendar';
import MainEndpoint from '@/endpoints/main';
import AffaireBase from '@/class/AffaireBase';
import { mapState } from 'pinia';
import Notify from '@/helper/Notification';
import AffaireStore from '@/store/AffaireStore';
import { sprintf } from 'sprintf-js';

export default defineComponent({
  name: 'SearchEngine',
  components: {
    ResultatAffaires,
    GuiH1,
    ListeAffaires,
    GuiInputText,
    GuiButtonPrimary,
    DatePicker,
  },
  data() {
    return {
      searchResult: [] as Array<AffaireBase>,
      jobID: 0,
      search: {
        type: undefined,
        status: undefined,
        order: undefined,
        internalorder: undefined,
        customercode: undefined,
        closedmin: undefined,
        closedmax: undefined,
        jobopeningmin: undefined,
        jobopeningmax: undefined,
        deadlinemin: undefined,
        deadlinemax: undefined,
        intdeadlinemin: undefined,
        intdeadlinemax: undefined,
      },
    };
  },
  computed: {
    ...mapState(AffaireStore, ['affaires']),
  },
  methods: {
    jobIDChanged(value: number) {
      this.jobID = value;
    },
    addById(id: string) {
      if (this.affaires.filter((a) => a.Job === parseInt(id, 10)).length > 0) {
        Notify.notifyInfo('Cette affaire est déjà dans la liste des affaires affichées');
        return;
      }
      Notiflix.Loading.hourglass('Chargement de l\'affaire...', { svgColor: 'rgb(99 102 241)' });
      AffaireStore().loadJob(id, () => {
        this.getAssociate(id);
        Notiflix.Loading.remove();
      });
    },
    async getAssociate(id: string) {
      const affaire = this.affaires.find((a) => a.Job === parseInt(id, 10));
      if (affaire === undefined || !affaire.getIsForecasts()) {
        return;
      }
      await affaire.addAssociateTaskList();
    },
    commitSearch() {
      const undefinedCheck = Object.values(this.search).filter((element) => element !== undefined);
      const url = new URL(window.location.origin);
      if (undefinedCheck.length === 0) {
        // eslint-disable-next-line no-alert
        Notiflix.Notify.failure('Votre recherche est vide');
      } else {
        Object.entries(this.search).forEach((entry) => {
          let value = entry[1] as undefined | Date | string | null;
          if (value instanceof Date) {
            value = `${value.getFullYear()}-${sprintf('%02d', value.getMonth() + 1)}-${sprintf('%02d', value.getDate())}`;
          }
          if (value === undefined || value === null) {
            return;
          }
          url.searchParams.set(entry[0], value);
        });
        Notiflix.Loading.hourglass('Chargement de la recherche...', { svgColor: 'rgb(236 72 153)' });
        MainEndpoint.getAxiosInstance().get(`/affaire/search?${url.searchParams.toString()}`).then((response) => {
          const searchResultAxios = new Array<AffaireBase>();
          if (response.data instanceof Array) {
            response.data.forEach((affaire: AffaireBase) => {
              const affaireBase = new AffaireBase();
              affaireBase.fillFromJSON(affaire);
              searchResultAxios.push(affaireBase);
            });
          }
          this.searchResult = searchResultAxios;
        }).catch((error) => {
          console.log(error);
          Notiflix.Notify.failure('Une erreur est apparu lors de la recherche.');
        })
          .finally(() => {
            Notiflix.Loading.remove();
          });
      }
    },
  },
});
