
import { defineComponent } from 'vue';
import Affaire from '@/class/Affaire';
import DateHelper from '@/helper/DateHelper';

export default defineComponent({
  name: 'TimelineSummary',
  props: {
    affaire: {
      type: Affaire,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  computed: {
    colSize() {
      if (this.type === 'DAY') {
        return 72;
      }
      return this.sizeCol;
    },
    leftPos() {
      const minDate = this.affaire.getMinDateRouting();
      if (minDate === undefined) return 0;
      if (this.type === 'WEEK') {
        return (DateHelper.getWeekDatesCount(this.startDateGantt, minDate) - 1) * this.colSize;
      }
      if (this.type === 'DAY') {
        return (DateHelper.getBusinessDatesCount(this.startDateGantt, minDate) - 1)
          * this.colSize;
      }
      return 0;
    },
    width() {
      const minDate = this.affaire.getMinDateRouting();
      const maxDate = this.affaire.getMaxDateRouting();

      if (minDate !== undefined && maxDate !== undefined) {
        if (this.type === 'WEEK') {
          return DateHelper.getWeekDatesCount(minDate, maxDate) * this.colSize;
        }
        if (this.type === 'DAY') {
          return DateHelper.getBusinessDatesCount(minDate, maxDate) * this.colSize;
        }
      }
      return 0;
    },
  },
});
