
import { ComponentPublicInstance, defineComponent } from 'vue';
// import GanttDaily from '@/components/gantt-daily/GanttDaily.vue';
import GanttWeekly from '@/components/gantt-weekly/GanttWeekly.vue';
import DraggableWindow from '@/components/draggable-window/DraggableWindow.vue';
import HelperBox from '@/components/gui/HelperBox.vue';
import { mapState } from 'pinia';
import GanttStore from '@/store/GanttStore';
import HourWeekDistributor from '@/components/gantt-shared/windows/HourWeekDistributor.vue';
import RankRouting from '@/class/RankRouting';
import weekHourDistributorBus from '@/bus/weekHourDistributorBus';
import DraggableWindowStore from '@/store/DraggableWindowStore';

export default defineComponent({
  name: 'GanttIndex',
  components: {
    // GanttDailyVue: GanttDaily,
    GanttWeeklyVue: GanttWeekly,
    DraggableWindow,
    HelperBox,
    HourWeekDistributor,
  },

  data() {
    return {
      // vue: 1,
      routingWeekDistributor: undefined as undefined | RankRouting,
      zindex: DraggableWindowStore().currentIndex,
    };
  },
  computed: {
    ...mapState(GanttStore, {
      options: 'options',
    }),
  },
  methods: {
    openOptionsWindow() {
      const optionsWindow = this.$refs.windowOptions as
        ComponentPublicInstance<typeof DraggableWindow>;
      optionsWindow.open();
    },
    openWindowDistributor(routing: RankRouting) {
      this.routingWeekDistributor = routing;
    },
    closeWindowDistributor() {
      this.routingWeekDistributor = undefined;
    },
  },
  unmounted() {
    weekHourDistributorBus.off('open', this.openWindowDistributor);
  },
  mounted() {
    weekHourDistributorBus.on('open', this.openWindowDistributor);

    const optionsWindow = this.$refs.windowOptions as
      ComponentPublicInstance<typeof DraggableWindow>;
    optionsWindow.close();
  },
});
