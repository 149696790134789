import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-x-0 top-0 h-px bg-gray-800/50"
}
const _hoisted_2 = { class: "absolute left-0 top-0" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-x-0 top-0 h-px bg-gray-800/50"
}
const _hoisted_4 = { class: "absolute left-0 top-0" }
const _hoisted_5 = {
  key: 1,
  class: "relative",
  style: {"top":"24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gantt_value_parent_timeline = _resolveComponent("gantt-value-parent-timeline")!
  const _component_gantt_value_poste = _resolveComponent("gantt-value-poste")!

  return (this.task !== undefined && this.task.affaire.showRanksInGantt)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["absolute m-0", {['bg-gray-100']: this.shouldShow}]),
        style: _normalizeStyle({
    'height': this.height+'px',
    'top': this.offsetTop+'px',
    'width': this.ganttWidth+'px'})
      }, [
        (_ctx.shouldShow)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_gantt_value_parent_timeline, {
            "start-date-gantt": this.startDateGantt,
            "end-date-gantt": this.endDateGantt,
            type: this.viewType,
            rank: _ctx.task,
            "size-col": this.sizeCol,
            postes: this.task.getRoutingsToShow(true)
          }, null, 8, ["start-date-gantt", "end-date-gantt", "type", "rank", "size-col", "postes"])
        ]),
        (_ctx.shouldShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "relative",
              style: {"top":"24px"},
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setup && _ctx.setup(...args)), ["ctrl","left"])),
              onContextmenu: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.restoreData && _ctx.restoreData(...args)), ["ctrl","right"]))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.tassk.getRoutingsToShow(true), (poste, index) => {
                return (_openBlock(), _createBlock(_component_gantt_value_poste, {
                  "start-date-gantt": this.startDateGantt,
                  "end-date-gantt": this.endDateGantt,
                  routings: this.tassk.getRoutingsToShow(true),
                  index: index,
                  type: this.viewType,
                  key: poste.IdRouting,
                  "size-col": this.sizeCol
                }, null, 8, ["start-date-gantt", "end-date-gantt", "routings", "index", "type", "size-col"]))
              }), 128))
            ], 32))
          : _createCommentVNode("", true)
      ], 6))
    : (this.jobGroupedRouting !== undefined && this.jobGroupedRouting.showRanksInGantt && this.tasksGroupedFilteredWeekAndCostCenter())
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["absolute m-0", {'bg-gray-100': _ctx.shouldShow}]),
          style: _normalizeStyle({
    'height': this.height+'px',
    'top': this.offsetTop+'px',
    'width': this.ganttWidth+'px'})
        }, [
          (_ctx.shouldShow)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_gantt_value_parent_timeline, {
              "start-date-gantt": this.startDateGantt,
              "end-date-gantt": this.endDateGantt,
              type: this.viewType,
              "size-col": this.sizeCol,
              affaire: this.jobGroupedRouting,
              postes: this.jobGroupedRouting.getGroupRoutingsToShow()
            }, null, 8, ["start-date-gantt", "end-date-gantt", "type", "size-col", "affaire", "postes"])
          ]),
          (_ctx.shouldShow)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.jobGroupedRouting.getGroupRoutingsToShow(), (poste, index) => {
                  return (_openBlock(), _createBlock(_component_gantt_value_poste, {
                    "start-date-gantt": this.startDateGantt,
                    "end-date-gantt": this.endDateGantt,
                    routings: this.jobGroupedRouting.getGroupRoutingsToShow(),
                    index: index,
                    type: this.viewType,
                    "size-col": this.sizeCol,
                    key: poste.IdRouting
                  }, null, 8, ["start-date-gantt", "end-date-gantt", "routings", "index", "type", "size-col"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true)
}