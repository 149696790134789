import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-full", {'bg-blue-100': this.oddYear,
       'bg-gray-200': !this.oddYear}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["border-black", {'border-black-l-2': this.isFirstDayOfYear,
    'border-r-2': this.isLastDayOfYear}]),
      style: _normalizeStyle({width: this.sizeCol + 'px'})
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(this.isFirstDayOfYear || this.isLastDayOfYear ? this.getYear : '​'), 1)
    ], 6)
  ], 2))
}