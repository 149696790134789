
import { defineComponent, PropType } from 'vue';
import DateHelper from '@/helper/DateHelper';
import ganttWirelessCollapseRank from '@/bus/ganttWirelessCollapseRank';
import AffaireRank from '@/class/AffaireRank';
import BaseRouting from '@/class/BaseRouting';
import Affaire from '@/class/Affaire';
import ganttWirelessCollapseGroupedRank from '@/bus/ganttWirelessCollapseGroupedRank';

export default defineComponent({
  name: 'GanttValueParentTimeline',
  props: {
    rank: {
      type: AffaireRank,
      required: false,
    },
    affaire: {
      type: Affaire,
      required: false,
    },
    postes: {
      type: Object as PropType<BaseRouting[]>,
      required: true,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    endDateGantt: {
      type: Date,
      required: true,
    },
    type: {
      type: String,
      default: () => 'DAY',
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  methods: {
    toggleCollapseRank() {
      if (this.rank !== undefined) {
        ganttWirelessCollapseRank.trigger('collapse', this.rank);
      }
      if (this.rank === undefined && this.affaire !== undefined) {
        ganttWirelessCollapseGroupedRank.trigger('collapse', this.affaire);
      }
    },
  },
  computed: {
    colSize() {
      if (this.type === 'DAY') {
        return 72;
      }
      return this.sizeCol;
    },
    leftPost() {
      let minDate: Date | undefined;
      this.postes.forEach((poste) => {
        if (minDate === undefined) {
          minDate = poste.startDate;
          return;
        }
        minDate = DateHelper.min(minDate, poste.startDate);
      });
      if (minDate instanceof Date) {
        if (this.type === 'WEEK') {
          return (DateHelper.getWeekDatesCount(this.startDateGantt, minDate) - 1) * this.colSize;
        }
        if (this.type === 'DAY') {
          return (DateHelper.getBusinessDatesCount(this.startDateGantt, minDate) - 1)
            * this.colSize;
        }
      }
      return 0;
    },
    size() {
      let minDate: Date | undefined;
      let maxDate: Date | undefined;
      this.postes.forEach((poste) => {
        if (minDate === undefined) {
          minDate = poste.startDate;
          maxDate = poste.endDate;
          return;
        }
        minDate = DateHelper.min(minDate, poste.startDate);
        maxDate = DateHelper.max(maxDate as Date, poste.endDate);
      });
      if (minDate instanceof Date && maxDate instanceof Date) {
        if (this.type === 'WEEK') {
          return DateHelper.getWeekDatesCount(minDate, maxDate) * this.colSize;
        }
        if (this.type === 'DAY') {
          return DateHelper.getBusinessDatesCount(minDate, maxDate) * this.colSize;
        }
      }
      return 0;
    },
  },
});
