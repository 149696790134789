
import { defineComponent, PropType } from 'vue';
import DateHelper from '@/helper/DateHelper';
import BaseRouting from '@/class/BaseRouting';
import { clone } from 'lodash';
import RankRouting from '@/class/RankRouting';
import RankHelper from '@/helper/RankHelper';
import GroupedRouting from '@/class/GroupedRouting';
import TimeHelper from '@/helper/TimeHelper';

export default defineComponent({
  name: 'HourWeekDistributor',
  emits: ['onClose'],
  data() {
    return {
      cacheWeeksHoursDistributor: this.formatArrayHours(clone(this.routing.weeksHoursDistributor)),
    };
  },
  props: {
    routing: {
      type: Object as PropType<BaseRouting>,
      required: true,
    },
  },
  watch: {
    'routing.weeksHoursDistributor': {
      handler(newValue) {
        this.cacheWeeksHoursDistributor = clone(newValue);
      },
      deep: true,
    },
  },
  computed: {
    costCenterName(): string {
      if (this.routing instanceof RankRouting) {
        return this.routing.CostCenterObject?.Group ?? '';
      }
      if (this.routing instanceof GroupedRouting) {
        return this.routing.getGroup();
      }
      return '';
    },
    rang(): undefined | {classColor: string, level: number, rank: string} {
      if (!(this.routing instanceof RankRouting)) {
        return undefined;
      }
      if (this.routing.Rank === undefined) {
        return undefined;
      }
      return {
        classColor: this.getColorLevel(this.routing.Rank.Level),
        level: this.routing.Rank.Level,
        rank: this.routing.Rank.Rank,
      };
    },
    totalHourDistributor() {
      const totalWeekHours = this.cacheWeeksHoursDistributor.reduce((prev: number, val: number) => TimeHelper.fixFormatHoursMinuteNumber(prev) + TimeHelper.fixFormatHoursMinuteNumber(val), 0);
      return TimeHelper.fixFormatHoursMinute(totalWeekHours);
    },
    totalHoursRank() {
      if (this.routing instanceof RankRouting) {
        return TimeHelper.fixFormatHoursMinute(this.routing.getManuTimeWithCoef());
      }
      return TimeHelper.fixFormatHoursMinute(this.routing.ManuTime);
    },
    businessDay() {
      return DateHelper.getWeekDatesCount(this.routing.getStartDate(), this.routing.getEndDate());
    },
  },
  methods: {
    save() {
      this.$emit('onClose');
      // eslint-disable-next-line vue/no-mutating-props
      this.routing.weeksHoursDistributor = clone(this.cacheWeeksHoursDistributor);
      this.routing.persistDate();
    },
    getColorLevel(level: number) {
      return RankHelper.getColorLevel(level);
    },
    formatArrayHours(hours: number[]) {
      const formattedHours: number[] = [];
      hours.forEach((hour) => {
        formattedHours.push(TimeHelper.fixFormatHoursMinuteNumber(hour));
      });
      return formattedHours;
    },
  },
});
