import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e631a542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative top-0 transform -translate-y-1/2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "absolute left-1/2 -top-px transform -translate-x-1/2"
}
const _hoisted_4 = { class: "flex w-full h-full justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative group", {
          'bg-gray-800': this.groupMoving,
          'p-1': this.groupMoving
        }]),
    style: _normalizeStyle({
          'top': this.index*5+this.offsetTop-1+'px',
          'width': this.sizeWidth+'px',
          'left': this.leftPos-1+'px',
        })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative z-20 group h-4 hover:h-5 hover:z-50 text-xs text-hours", {
          'animate-pulse': this.grab.isMooving,
          [this.color]: true,
          'h-5': this.type === 'DAY',
          'h-3': !this.grab.isMooving && !this.grab.isResizingStart && !this.grab.isResizingEnd && !this.type === 'DAY',
          'z-50': this.grab.isMooving || this.grab.isResizingStart || this.grab.isResizingEnd,
       }]),
      style: {"cursor":"ew-resize"}
    }, [
      _createElementVNode("div", {
        class: "relative inset-0 text-white group-hover:block",
        onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startDrag && _ctx.startDrag(...args))),
        onContextmenu: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openWindowWeekDistributor($event)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          (this.type === 'WEEK')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.poste.weeksHoursDistributor, (weekHours, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "absolute -top-[2px] text-center",
                    style: _normalizeStyle({width: this.colSize+'px', left: this.colSize*index + 'px'})
                  }, _toDisplayString(_ctx.TimeHelper.fixFormatHoursMinute(weekHours)) + "h ", 5))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.TimeHelper.fixFormatHoursMinute(_ctx.manuTime)) + "h ", 1))
        ])
      ], 32),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startResizeStart && _ctx.startResizeStart(...args))),
          class: _normalizeClass([{
              'animate-pulse': this.grab.isResizingStart
            }, "w-3 h-full bg-white opacity-25"]),
          style: {"cursor":"col-resize"}
        }, null, 34),
        _createElementVNode("div", {
          onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.startResizeEnd && _ctx.startResizeEnd(...args))),
          class: _normalizeClass([{
              'animate-pulse': this.grab.isResizingEnd
            }, "w-3 h-full bg-white opacity-25"]),
          style: {"cursor":"col-resize"}
        }, null, 34)
      ])
    ], 2)
  ], 6))
}