
import { defineComponent } from 'vue';
import UserEntity from '@/class/UserEntity';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import TimeHelper from '../../helper/TimeHelper';

export default defineComponent({
  name: 'OperatorDetails',
  props: {
    rwp: {
      type: RoutingWeekPlanning,
      required: true,
    },
    operator: {
      type: UserEntity,
      required: true,
    },
  },
  data() {
    return {
      showDetail: true,
    };
  },
  computed: {
    TimeHelper() {
      return TimeHelper;
    },
    operatorDayPlannings() {
      return this.rwp.routingsDayPlanning.filter((rdp) => rdp.operator.User === this.operator.User)
        .sort((a, b) => {
          if (a.startDate < b.startDate) {
            return -1;
          }
          if (a.startDate > b.startDate) {
            return 1;
          }
          return 0;
        });
    },
    totalMinutesOperator() {
      let total = 0;
      this.operatorDayPlannings.forEach((rdp) => {
        total += rdp.minutes;
      });
      return total;
    },
    startAtOperator() {
      return this.formatDateHourMinutes(this.operatorDayPlannings[0].startDate);
    },
    endAtOperator() {
      return this.formatDateHourMinutes(
        this.operatorDayPlannings[this.operatorDayPlannings.length - 1].getEndDate(),
      );
    },
  },
  methods: {
    formatDateHourMinutes(date: Date) {
      return `${(date.getHours() < 10 ? `0${date.getHours()}` : date.getHours())}:${(date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes())}`;
    },
  },
});
