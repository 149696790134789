

import { defineComponent, PropType } from 'vue';
import CostCenter from '@/class/CostCenter';
import CostCenterCol from '@/components/gantt-weekly/cost-center/CostCenterCol.vue';
import ganttRoutingUpdatingBus from '@/bus/gantRoutingUpdatingBus';
import Notiflix from 'notiflix';
import CostCenterHelper from '@/helper/CostCenterHelper';
import DateHelper from '@/helper/DateHelper';

export default defineComponent({
  name: 'CostCenterValue',
  components: { CostCenterCol },
  data() {
    return {
      isUpdating: false,
      totalAllTask: [] as Array<Array<Array< { [key: string]: { hours: number } } >>>,
      isLoadingMaxCostCenter: false,
      firstNumberWeek: DateHelper.getWeek(this.startDateGantt),
    };
  },
  props: {
    jitRendering: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    costCenterToShow: {
      type: Object as PropType<Array<CostCenter>>,
      required: true,
    },
    ganttWidth: {
      type: Number,
      default: () => 0,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    endDateGantt: {
      type: Date,
      required: true,
    },
    weekToShow: {
      type: Number,
      required: true,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  created() {
    this.getArrayTotalTime();
  },
  methods: {
    async getArrayTotalTime() {
      const totalTask = await CostCenterHelper.getTotalHoursByGroup(this.startDateGantt.getFullYear(), this.firstNumberWeek, this.firstNumberWeek + (this.weekToShow - 1));
      if (totalTask !== undefined) {
        this.totalAllTask = totalTask;
      }
      this.isLoadingMaxCostCenter = true;
    },
    getTotalHours(costCenterGroup :any, weekSequel :number) {
      const newDate = DateHelper.addDays(this.startDateGantt, (weekSequel - 1) * 7);
      const year = newDate.getFullYear();
      const weekNumber = DateHelper.getWeek(newDate);

      if (this.totalAllTask[year] !== undefined
        && this.totalAllTask[year][weekNumber] !== undefined
        && this.totalAllTask[year][weekNumber][costCenterGroup] !== undefined
      ) {
        const totalHours = this.totalAllTask[year][weekNumber][costCenterGroup];

        if (typeof totalHours === 'number') {
          return totalHours / 100;
        }
      } else {
        if (this.totalAllTask[0] === undefined || this.totalAllTask[0][0] === undefined) return 0;
        const defaultTotalHours = this.totalAllTask[0][0][costCenterGroup];
        if (typeof defaultTotalHours === 'number') {
          return defaultTotalHours / 100;
        }
      }

      return 0;
    },
    setUpdating(value: boolean) {
      this.isUpdating = value;

      if (!this.jitRendering) {
        if (value) {
          Notiflix.Block.circle([this.$refs.jitContainer as HTMLElement], 'JIT désactivé, en attente de la fin de modification', {
            messageMaxLength: 999,
            messageColor: 'black',
            svgColor: 'black',
          });
        } else {
          Notiflix.Block.remove([this.$refs.jitContainer as HTMLElement]);
        }
      }
    },
  },
  computed: {
    costCenterGroups() {
      return CostCenterHelper.getCostCenterToShowGroups();
    },
  },
  mounted() {
    ganttRoutingUpdatingBus.on('isEditing', this.setUpdating);
  },
  unmounted() {
    ganttRoutingUpdatingBus.off('isEditing', this.setUpdating);
  },
});
