import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inline-block" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-px bg-white rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["text-lg hover:text-white/50 transition font-light", {'text-white': _ctx.isSelected}])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_ctx.isSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}