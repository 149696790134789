import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["absolute h-1 overflow-hidden", {[this.backgroundColor]: true}]),
    style: _normalizeStyle({top: 8+(5*this.position) + 'px', left: _ctx.leftPos + 'px', width:  _ctx.width +'px'})
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute w-full animate-scroll", {
      'calc-dashed': !_ctx.isGrouped,
      'calc-dashed-grouped': _ctx.isGrouped,
    }])
    }, null, 2)
  ], 6))
}