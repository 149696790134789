
import {
  defineComponent,
} from 'vue';
import AffaireRank from '@/class/AffaireRank';
import Line from '@/components/planificateur/Line.vue';

export default defineComponent({
  components: { Line },
  props: {
    value: {
      type: AffaireRank,
      required: true,
    },
    parentStep: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      showChildrens: false,
    };
  },
  computed: {
    nextStep() {
      return this.parentStep + 1;
    },
  },
  methods: {
    toggleShowChildrens() {
      this.showChildrens = !this.showChildrens;
    },
  },
});

