
import WeekCol from '@/components/gantt-weekly/WeekCol.vue';
import { mapState } from 'pinia';
import SideBarAffaire from '@/components/gantt-shared/sidebar/SideBarAffaire.vue';
import GanttTaskEventBus from '@/bus/ganttTaskUpdateShowBus';
import GanttValueTask from '@/components/gantt-shared/content/GanttValueTask.vue';
import DateHelper from '@/helper/DateHelper';
import ganttScrollEvent from '@/bus/ganttScrollEvent';
import ganttMultiPostMove from '@/bus/ganttMultiPostMove';
import CostCenterSidebar from '@/components/gantt-shared/cost-center/CostCenterSidebar.vue';
import GanttContentHeader from '@/components/gantt-shared/content-header/GanttContentHeader.vue';
import CostCenterValue from '@/components/gantt-weekly/cost-center/CostCenterValue.vue';
import CostCenterHelper from '@/helper/CostCenterHelper';
import { ComponentPublicInstance, defineComponent } from 'vue';
import AffaireStore from '@/store/AffaireStore';
import ganttRoutingUpdatingBus from '@/bus/gantRoutingUpdatingBus';
import GanttStore from '@/store/GanttStore';
import GanttTaskUpdateBusType from '@/type/GanttTaskUpdateBusType';
import Affaire from '@/class/Affaire';
import ganttAddColumnBus from '@/bus/ganttAddColumnBus';
import { clone } from 'lodash';
import loadNewAffaireBus from '@/bus/loadNewAffaireBus';
import Multiselect from '@vueform/multiselect';
import YearWeekCol from '@/components/gantt-weekly/YearWeekCol.vue';
import ganttCloseAllAffaireBus from '@/bus/ganttCloseAllAffaireBus';

export default defineComponent({
  name: 'GanttWeeklyVue',
  components: {
    CostCenterSidebar,
    GanttValueTask,
    SideBarAffaire,
    WeekCol,
    CostCenterValue,
    GanttContentHeader,
    Multiselect,
    YearWeekCol,
  },
  props: {
    weekToShow: {
      type: Number,
      default: () => 1,
    },
  },
  watch: {
    'options.showCostCenter': {
      async handler(value: boolean) {
        if (!value) return;
        await this.$nextTick();
        this.updateScrollCostCentersAndTopCols();
      },
    },
  },
  data() {
    return {
      tasksShow: {} as {
        [key: number]: GanttTaskUpdateBusType
      },
      groupedTasksShow: {} as {
        [key: number]: GanttTaskUpdateBusType,
      },
      ganttScroll: 0,
      startDateCalculatedFromRoutings: new Date(),
      endDateCalculatedFromRoutings: new Date(),
      weeksAfter: 0,
      weeksBefore: 0,
      startWeekShow: 0,
      costCenterRerender: 0,
      sizeCol: 92,
      isLoading: false,
      affaireArray: [] as Affaire[],
      sidebarReload: 0,
      isLoadingAffaires: true as boolean,
      affairesStock: new Array<Affaire>() as Array<Affaire>,
      lastNumberFilter: -1 as number,
      env: process.env.VUE_APP_ENV,
      firstYearDisplay: 2023,
      lastYearDisplay: 2024,
      isShow: false,
    };
  },
  methods: {
    updateShowGantt() {
      if (this.isShow) {
        ganttCloseAllAffaireBus.trigger('closeAll', this.isShow);
      } else {
        ganttCloseAllAffaireBus.trigger('openAll', this.isShow);
      }
      this.isShow = !this.isShow;
    },
    resetSideBarLoad(affaires: Array<Affaire>) {
      this.sidebarReload += 1;
      this.affaireArray = affaires;
      this.isLoading = (affaires.length === 0);
    },
    sideBarLoaded(affaire: Affaire) {
      if (this.affaireArray[this.affaireArray.length - 1] === affaire) {
        this.isLoading = true;
      }
    },
    forceRerender() {
      this.costCenterRerender += 1;
    },
    getSidebarDiv(affaire: Affaire): ComponentPublicInstance<typeof SideBarAffaire> | undefined {
      const divs = this.$refs[`sidebar-${affaire.Job}`] as ComponentPublicInstance<typeof SideBarAffaire>[] | undefined;
      if (divs === undefined) {
        return undefined;
      }
      if (divs.length === 0) {
        return undefined;
      }
      return divs[0];
    },
    moveTaskOutside() {
      const gantt = this.$refs.gantt as HTMLDivElement;
      const ganttScrollLeftBeforeAdd = gantt.scrollLeft;
      this.addWeekBefore();
      gantt.scroll(ganttScrollLeftBeforeAdd + this.sizeCol, 0);
    },
    addWeekBefore() {
      this.weeksBefore += 1;
      CostCenterHelper.recalculateCostCenterWeekly(this.numberOfWeek, this.startDateGantt);
    },
    addWeekAfter() {
      this.weeksAfter += 1;
      CostCenterHelper.recalculateCostCenterWeekly(this.numberOfWeek, this.startDateGantt);
    },
    addDays: DateHelper.addDays,
    setToMonday: DateHelper.setToMonday,
    updateTaskShow(data: GanttTaskUpdateBusType | null) {
      const event = data as GanttTaskUpdateBusType;
      if (event.task !== undefined) {
        this.tasksShow[event.task.IdJobFile] = data as
          GanttTaskUpdateBusType;
      }
      if (event.groupedRoutings !== undefined) {
        this.groupedTasksShow[event.groupedRoutings.Job] = event;
      }
      this.refreshGanttViewValuePosition();
    },
    resetAllTaskShow() {
      this.tasksShow = {};
      this.refreshGanttViewValuePosition();
    },
    // Because vue cannot watch HTMLDivElement object
    refreshGanttViewValuePosition(): void {
      this.$nextTick(() => {
        const ganttValues = this.$refs.ganttValueTask as
          Array<ComponentPublicInstance<typeof GanttValueTask>> | undefined;

        const ganttContentHeaders = this.$refs.ganttContentHeader as
          Array<ComponentPublicInstance<typeof GanttContentHeader>> | undefined;

        if (ganttValues !== undefined) {
          ganttValues.forEach((el) => {
            el.updateOffsetPosition();
          });
        }
        if (ganttContentHeaders !== undefined) {
          ganttContentHeaders.forEach((el) => {
            el.updateOffsetPosition();
          });
        }
      });
    },
    updateScrollCostCentersAndTopCols(): void {
      const gantt = this.$refs.gantt as HTMLDivElement;
      const costCenter = this.$refs.costCenterScroll as HTMLDivElement;
      const topBar = this.$refs.topBarScroll as HTMLDivElement;
      const yearTopBarScroll = this.$refs.yearTopBarScroll as HTMLDivElement;
      costCenter?.scroll(gantt.scrollLeft, 0);
      topBar?.scroll(gantt.scrollLeft, 0);
      yearTopBarScroll?.scroll(gantt.scrollLeft, 0);
      this.updateYearToShow(yearTopBarScroll);
    },
    onEditCostCenters(value: boolean): void {
      if (value) return;
      this.$nextTick(() => {
        this.updateScrollCostCentersAndTopCols();
        if (!this.options.JITRendering) {
          CostCenterHelper.recalculateCostCenterWeekly(this.numberOfWeek, this.startDateGantt);
        }
      });
    },
    setIsLoadingAffaire(isLoading: boolean) {
      this.isLoadingAffaires = isLoading;
    },
    addCostCenterFilter(value: string) {
      GanttStore().addCostCenterGroupFilter(value);
    },
    removeCostCenterFilter(value: string) {
      GanttStore().removeCostCenterGroupFilter(value);
    },
    clearCostCenterFilter() {
      GanttStore().removeAllCostCenterGroupFilter();
    },
    clearWeekFilter() {
      GanttStore().removeAllWeekFilter();
    },
    clearCostcenterAndWeekFilter() {
      GanttStore().removeAllFilter();
    },
    textBadgeColor(group: string) {
      return {
        [CostCenterHelper.getBackgroundColorFromGroup(group)]: true,
      };
    },
    toggleShowCostCenter() {
      this.options.showCostCenter = !this.options.showCostCenter;
    },
    updateYearToShow(yearTopBarScroll: HTMLDivElement) {
      let firstWeekDisplay = Math.round((yearTopBarScroll.scrollLeft / this.sizeCol) - 8);
      let lastWeekDisplay = 0;
      if (firstWeekDisplay <= 0) {
        lastWeekDisplay = Math.round((yearTopBarScroll.clientWidth / this.sizeCol) + firstWeekDisplay);
        firstWeekDisplay = 1;
      } else {
        firstWeekDisplay += 1;
        lastWeekDisplay = Math.round((yearTopBarScroll.clientWidth / this.sizeCol) + firstWeekDisplay) - 1;
      }
      firstWeekDisplay *= 7;
      lastWeekDisplay *= 7;

      this.firstYearDisplay = DateHelper.addDays(this.startDateGantt, firstWeekDisplay).getFullYear();
      this.lastYearDisplay = DateHelper.addDays(this.startDateGantt, Math.round(lastWeekDisplay)).getFullYear();
    },
  },
  computed: {
    ...mapState(AffaireStore, {
      affaires: 'sortedAffairesByCustomerCode',
      affairesSearch: 'sortedAffairesSearchByCustomerCode',
      inputFilter: 'inputFilter',
      startDate: 'getMinDateRoutingInAllAffaire',
      endDate: 'getMaxDateRoutingInAllAffaire',
    }), // From vuex
    ...mapState(GanttStore, {
      options: 'options',
      costCenterGroupFilter: 'costCenterGroupFilter',
    }),
    getCostCenterFilterOption() {
      return CostCenterHelper.getCostCenterToShowGroups();
    },
    numberOfWeek() {
      let numberOfWeek = DateHelper.getBusinessDatesCount(this.startDateGantt, this.endDateGantt) / 5;
      if (numberOfWeek < 13) {
        for (let i = 0; i < numberOfWeek + 1; i += 1) {
          this.addWeekAfter();
        }
        numberOfWeek = 13;
      }
      return Math.ceil(
        numberOfWeek,
      );
    },
    insideWidthGant() {
      return this.sizeCol * this.numberOfWeek;
    },
    endDateGantt() {
      const date = DateHelper.setToFriday(
        DateHelper.addDays(
          new Date(this.endDateCalculatedFromRoutings as Date),
          7 * (this.weeksAfter + 1),
        ),
      );
      date.setHours(0, 0, 0, 0);
      return date;
    },
    startDateGantt(): Date {
      const date = DateHelper.addDays(DateHelper.setToMonday(
        new Date(this.startDateCalculatedFromRoutings as Date),
      ), -7 * (this.weeksBefore + 1));
      date.setHours(0, 0, 0, 0);
      return date;
    },
    costCenterToShow() {
      return CostCenterHelper.getCostCenterToShow();
    },
    getFilteredAffaires(): Array<any> {
      const numberOfFilter = GanttStore().getNumberOfFilter() + this.inputFilter.length;

      if (this.isLoadingAffaires || this.lastNumberFilter !== numberOfFilter || this.inputFilter.length !== 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.lastNumberFilter = numberOfFilter;
        const affaires: Array<Affaire> = clone(this.inputFilter.length === 0 ? this.affaires : this.affairesSearch);
        this.resetSideBarLoad(affaires);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.affairesStock = affaires;
        this.setIsLoadingAffaire(false);
        return affaires;
      }

      return this.affairesStock;
    },
  },
  unmounted() {
    GanttTaskEventBus.off('update', this.updateTaskShow);
    ganttRoutingUpdatingBus.off('isEditing', this.onEditCostCenters);
    GanttTaskEventBus.off('resetAllRanks', this.resetAllTaskShow);
    ganttAddColumnBus.off('addWeekBefore', this.moveTaskOutside);
    ganttAddColumnBus.off('addWeekAfter', this.addWeekAfter);
    loadNewAffaireBus.off('load', this.setIsLoadingAffaire);
  },
  mounted() {
    this.setIsLoadingAffaire(false);
    this.forceRerender();
    loadNewAffaireBus.on('loadNew', this.setIsLoadingAffaire);
    GanttTaskEventBus.on('update', this.updateTaskShow);
    ganttRoutingUpdatingBus.on('isEditing', this.onEditCostCenters);
    GanttTaskEventBus.on('resetAllRanks', this.resetAllTaskShow);
    ganttAddColumnBus.on('addWeekBefore', this.moveTaskOutside);
    ganttAddColumnBus.on('addWeekAfter', this.addWeekAfter);

    this.startDateCalculatedFromRoutings = new Date(this.startDate as Date);
    this.endDateCalculatedFromRoutings = new Date(this.endDate as Date);

    CostCenterHelper.recalculateCostCenterWeekly(this.numberOfWeek, this.startDateGantt);

    // Vertical scroll with wheel :D
    const gantt = this.$refs.gantt as HTMLDivElement;
    // eslint-disable-next-line no-bitwise
    const colSize = ~~(gantt.clientWidth / 13.5);

    if (colSize < 76) {
      this.sizeCol = 76;
    } else if (colSize > 126) {
      this.sizeCol = 126;
    } else {
      this.sizeCol = colSize;
    }

    gantt.onwheel = (event: WheelEvent) => {
      event.preventDefault();
      const scrollMove = event.deltaY > 0 ? this.sizeCol : -this.sizeCol;
      gantt.scroll(gantt.scrollLeft + scrollMove, 0);
      this.ganttScroll = gantt.scrollLeft;
      if (gantt.scrollLeft === 0 && this.startWeekShow === 0 && event.deltaY < 0) {
        this.addWeekBefore();
      } else if (gantt.scrollLeft === gantt.scrollWidth - gantt.clientWidth && event.deltaY > 0) {
        this.addWeekAfter();
        gantt.scrollLeft -= (this.sizeCol / 2);
      }
      this.updateScrollCostCentersAndTopCols();
      this.startWeekShow = gantt.scrollLeft;
      ganttScrollEvent.trigger('scroll', event);
    };

    document.onkeyup = (event: any) => {
      if (event.type === 'keyup' && event.key === 'Control') {
        ganttMultiPostMove.trigger('ctrRelease', 1);
      }
    };

    this.firstYearDisplay = DateHelper.addDays(this.startDateGantt, 1).getFullYear();
    this.lastYearDisplay = DateHelper.addDays(this.startDateGantt, 12).getFullYear();
  },
});
