
import { defineComponent } from 'vue';
import Affaire from '@/class/Affaire';
import AssociateAffaire from '@/class/AssociateAffaire';
import MainEndpoint from '@/endpoints/main';
import AffaireStore from '@/store/AffaireStore';

export default defineComponent({
  name: 'PlanificateurVue',
  data() {
    return {
      associateList: [] as AssociateAffaire[],
    };
  },
  props: {
    affaire: {
      type: Affaire,
      required: true,
    },
  },
  computed: {
    delaiClient() {
      const date = this.affaire.InitDeadline.split('-');
      return `${date[2]}/${date[1]}/${date[0]}`;
    },
  },
  methods: {
    async archived() {
      await MainEndpoint.getAxiosInstance().post(`affaire/archive/${this.affaire.Job}`);
      const index = AffaireStore().affaires.findIndex((affaire) => affaire.Job === this.affaire.Job);
      AffaireStore().removeAffaireFromIndex(index);
    },
    async getAssociateBusiness(affaire: Affaire) {
      if (!affaire.getIsForecasts() || affaire.associateTaskList.length > 0) {
        return;
      }
      await affaire.addAssociateTaskList();
    },
    getBgColor(affaire: Affaire, border: boolean) {
      if (affaire.getIsForecasts()) {
        let string = 'bg-trac';
        if (border) {
          string += ' border-trac-blue/80';
        } else {
          string = 'bg-trac-blue/80';
        }
        return string;
      }

      let string = 'bg-trac-blue/80';
      if (border) {
        string += ' border-trac';
      } else {
        string = 'bg-trac';
      }
      return string;
    },
    hasAffaireInterne() {
      if (
        this.affaire.Ranks.length === 1
        && this.affaire.Ranks[0].Postes.length === 1
        && this.affaire.Ranks[0].Postes[0].Boms.length === 1
        && this.affaire.Ranks[0].Postes[0].Boms[0].ProductionReservations.length === 1
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getAssociateBusiness(this.affaire);
  },
});
