
import { defineComponent } from 'vue';
import DateHelper from '@/helper/DateHelper';
import GanttStore from '@/store/GanttStore';
import { mapState } from 'pinia';

export default defineComponent({
  name: 'WeekCol',
  props: {
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    dayToAdd: {
      type: Number,
      default: () => 0,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  data() {
    return {
      colHover: false,
    };
  },
  computed: {
    ...mapState(GanttStore, {
      weeksFilter: 'weeksFilter',
    }),
    startDateFromMonday() {
      return DateHelper.addDays(this.startDate, this.dayToAdd);
    },
    isWeekFilterSelected() {
      return this.weeksFilter.find(
        (f) => f.valueOf() === this.startDateFromMonday.valueOf(),
      ) !== undefined;
    },
  },
  methods: {
    weekName: DateHelper.weekName,
    setToMonday: DateHelper.setToMonday,
    addDays: DateHelper.addDays,
    getWeekNumber(d: Date) {
      const date = new Date(d);
      // ISO week date weeks start on Monday, so correct the day number
      const nDay = (date.getDay() + 6) % 7;

      // ISO 8601 states that week 1 is the week with the first Thursday of that year
      // Set the target date to the Thursday in the target week
      date.setDate(date.getDate() - nDay + 3);

      // Store the millisecond value of the target date
      const n1stThursday = date.valueOf();

      // Set the target to the first Thursday of the year
      // First, set the target to January 1st
      date.setMonth(0, 1);

      // Not a Thursday? Correct the date to the next Thursday
      if (date.getDay() !== 4) {
        // eslint-disable-next-line no-mixed-operators
        date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
      }

      // The week number is the number of weeks between the first Thursday of the year
      // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
      return 1 + Math.ceil((n1stThursday - date.valueOf()) / 604800000);
    },
    toggleFilterWeek() {
      if (this.isWeekFilterSelected) {
        GanttStore().removeWeekFilter(new Date(this.startDateFromMonday));
      } else {
        GanttStore().addWeekFilter(new Date(this.startDateFromMonday));
      }
    },
  },
});
