import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-chevron-down"
}
const _hoisted_3 = {
  key: 1,
  class: "fas fa-chevron-right"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!
  const _component_line_with_children = _resolveComponent("line-with-children", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.value.Childs !== undefined && this.value.Childs.length !== 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute",
          style: _normalizeStyle({'left': 15*_ctx.parentStep-20+'px'})
        }, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowChildrens && _ctx.toggleShowChildrens(...args))),
            class: "p-2"
          }, [
            (_ctx.showChildrens)
              ? (_openBlock(), _createElementBlock("i", _hoisted_2))
              : (_openBlock(), _createElementBlock("i", _hoisted_3))
          ])
        ], 4))
      : _createCommentVNode("", true),
    _createVNode(_component_Line, {
      "parent-step": _ctx.parentStep,
      value: _ctx.value
    }, null, 8, ["parent-step", "value"]),
    (_ctx.showChildrens)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value.Childs, (children) => {
            return (_openBlock(), _createElementBlock("div", {
              key: children.IdJobFile
            }, [
              (children.Childs !== undefined && children.Childs.length !== 0)
                ? (_openBlock(), _createBlock(_component_line_with_children, {
                    key: 0,
                    "parent-step": _ctx.nextStep,
                    value: children
                  }, null, 8, ["parent-step", "value"]))
                : (_openBlock(), _createBlock(_component_Line, {
                    key: 1,
                    "parent-step": _ctx.nextStep,
                    value: children
                  }, null, 8, ["parent-step", "value"]))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}