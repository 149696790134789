import Notiflix from 'notiflix';

export default function WhatsNew(clb: undefined | (() => void) = undefined) {
  Notiflix.Report.info(
    'Quoi de neuf ?',
    ''
    + '<b>v.0.0.63</b> <br>'
    + '<b>Général : </b><br>'
    + '- Gestion des affaires en retard<br>'
    + '- Ajout des alert des phrases de sous traitance<br>'
    + '- Archiver les affaires<br>'
    + '- Ajoute des droits de modification du Gantt pour les admins<br>'
    + '- Ajout des alertes des nouvelles affaires<br>'
    + '<b>Vue Gantt : </b><br>'
    + '- Ajouter de picto de l\'états des nomenclatures<br>'
    + '- Correction des règles de déplacement des postes enfants parent<br>'
    + '- Ajout de picto de l\'avance des pointages<br>'
    + '<b>Vue Hebdo : </b><br>'
    + '- Correction problème d\'actualisation au changement des semaines<br>'
    + '- Correction problème des pointages définis par défaut le lundi<br>'
    + '<b>v.0.0.62</b> <br>'
    + '<b>Vue Gantt : </b><br>'
    + '- Ajout d\'un bouton pour ouvrir et fermer toutes les affaires<br>'
    + '- Ajout nouvelle contrainte tache enfant ne peut pas commencer avant la tache parente<br>'
    + '- Décalage de la deadline dépasser<br>'
    + '- Prise en compte jour d\'absence dans le calcul des heures<br>'
    + '- Ajout information de l\'année en cours du déroulement du Gantt<br>'
    + '- Ajout raccourci pour accéder au calcul de centre de frais<br>'
    + '- Possibilité de supprimer tous les filtres actifs<br>'
    + '- Ajout d\'une barre de filtre pour les centres de frais<br>'
    + '- Ajout filtre date écrire manuellement<br>'
    + '- Correction du format de date dans la pastille deadline <br>'
    + '- Correction des informations du filtre qui disparaisse au changement de page<br>'
    + '<b>Gestion des absence : </b><br>'
    + '- Changement de l\'affichage des jours d\'absence<br>'
    + '- Modification des heures par défaut des jours d\'absence<br>'
    + '<b>Gestion des absence : </b><br>'
    + '- Création de la nouvelle vue hebdomadaire<br>'
    + '<b>General : </b><br>'
    + 'Ajout du rôle planificateur<br>'
    + '<b>v.0.0.61</b> <br>'
    + '<b>Vue détaillé : </b><br>'
    + '- Ajout statut Affaire prévisionnel<br>'
    + '- Fix chargement des affaires affilier<br>'
    + '<b>General: </b><br>'
    + '- Récupération des pointages Clipper<br>'
    + '- Optimization de la recuperation des pointages clipper<br>'
    + '- Ajout règle, terminer les pointages des enfants quand un pointage parent est commencé.<br>'
    + '- Fix multi filtre<br>'
    + '<br>'
    + '<b>v.0.0.60</b> <br>'
    + '<b>Vue détaillé : </b><br>'
    + '- Ajout d\'indicateur de pointage des phases de gammes<br>'
    + '<b>Vue Opérateur : </b><br>'
    + '- Modification de l\'affichage de la sélection de jours<br>'
    + '- Ajout d\'etats de pointage des postes centre des opérateurs.<br>'
    + '<br><br>'
    + `${
      process.env.VUE_APP_ENV === 'DEV' || process.env.VUE_APP_ENV === 'PREPROD'
        ? '<i class="text-gray-400 font-bold">Ce message ne sera visible à la connexion qu\'en préprod</i>' : ''}`,
    'Compris !',
    clb,
    {
      width: '500px',
      plainText: false,
      messageMaxLength: 9999999999,
    },
  );
}
