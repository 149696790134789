

import { defineComponent } from 'vue';
import DraggableWindow from '@/components/draggable-window/DraggableWindow.vue';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import UserEntity from '@/class/UserEntity';
import OperatorDetails from '@/components/planningWindowDetail/OperatorDetails.vue';
import MainEndpoint from '@/endpoints/main';
import TimeHelper from '../../helper/TimeHelper';

export default defineComponent({
  name: 'PlanningWindowDetails',
  components: { OperatorDetails, DraggableWindow },
  props: {
    routingWeekPlanning: {
      type: RoutingWeekPlanning,
      required: true,
    },
  },
  data() {
    return {
      lastUpdate: Date.now(),
      comment: '',
      lastType: Date.now(),
      commentSent: true,
    };
  },
  methods: {
    commentUpdate() {
      this.commentSent = false;
      this.lastType = Date.now();
      const newComment = (this.$refs.commentTextArea as HTMLTextAreaElement).value;

      setTimeout(() => {
        if (Date.now() - this.lastType < 1000) {
          return;
        }
        MainEndpoint.getAxiosInstance().post(`/week-planning/${this.routingWeekPlanning.internalId}/update-comment`, {
          comment: newComment,
        }).then(() => {
          this.commentSent = true;
        });
      }, 1000);
    },
  },
  watch: {
  },
  computed: {
    TimeHelper() {
      return TimeHelper;
    },
    restMinutes() {
      return this.routingWeekPlanning.minutes
        - this.routingWeekPlanning.getTotalMinutesDayPlanning();
    },
    averageMinutesOnTask() {
      const total = this.routingWeekPlanning.getTotalMinutesDayPlanning();
      return Math.round((total / this.routingWeekPlanning.routingsDayPlanning.length) * 100) / 100;
    },
    differentOperatorsOnTask() {
      const operators = new Array<UserEntity>();
      this.routingWeekPlanning.routingsDayPlanning.forEach((rdp) => {
        if (operators.indexOf(rdp.operator) === -1) {
          operators.push(rdp.operator);
        }
      });
      return operators;
    },
  },
});
