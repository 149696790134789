
import { defineComponent } from 'vue';
import Affaire from '@/class/Affaire';
import CostCenterHelper from '@/helper/CostCenterHelper';
import DateHelper from '@/helper/DateHelper';
import CostCenter from '@/class/CostCenter';
import { mapState } from 'pinia';
import GanttStore from '@/store/GanttStore';

export default defineComponent({
  name: 'TimelineCostCenter',
  props: {
    position: {
      type: Number,
      default: 0,
    },
    costCenterGroup: {
      type: String,
      required: true,
    },
    affaire: {
      type: Affaire,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  computed: {
    ...mapState(GanttStore, {
      costCenterFilter: 'costCenterGroupFilter',
    }),
    backgroundColor() {
      return CostCenterHelper.getBackgroundColorFromGroup(this.costCenterGroup);
    },
    isGrouped() {
      return Object.keys(CostCenter.regroupedGroupId)
        .includes(this.costCenterGroup);
    },
    colSize() {
      if (this.type === 'DAY') {
        return 72;
      }
      return this.sizeCol;
    },
    leftPos() {
      const minDate = CostCenterHelper
        .getMinDateInJobByCostCenterGroup(this.affaire, this.costCenterGroup);
      if (minDate === undefined) return 0;
      if (this.type === 'WEEK') {
        return (DateHelper.getWeekDatesCount(this.startDateGantt, minDate) - 1) * this.colSize;
      }
      if (this.type === 'DAY') {
        return (DateHelper.getBusinessDatesCount(this.startDateGantt, minDate) - 1)
          * this.colSize;
      }
      return 0;
    },

    width() {
      if (this.costCenterFilter.length > 0 && !this.costCenterFilter.includes(this.costCenterGroup)) {
        return 0;
      }

      const minDate = CostCenterHelper
        .getMinDateInJobByCostCenterGroup(this.affaire, this.costCenterGroup);
      const maxDate = CostCenterHelper
        .getMaxDateInJobByCostCenterGroup(this.affaire, this.costCenterGroup);

      if (minDate !== undefined && maxDate !== undefined) {
        if (this.type === 'WEEK') {
          return DateHelper.getWeekDatesCount(minDate, maxDate) * this.colSize;
        }
        if (this.type === 'DAY') {
          return DateHelper.getBusinessDatesCount(minDate, maxDate) * this.colSize;
        }
      }
      return 0;
    },
  },
});
