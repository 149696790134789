import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_timeline_summary = _resolveComponent("timeline-summary")!
  const _component_timeline_cost_center = _resolveComponent("timeline-cost-center")!
  const _component_timeline_deadline = _resolveComponent("timeline-deadline")!

  return (_openBlock(), _createElementBlock("div", {
    class: "absolute m-0 bg-dashed",
    style: _normalizeStyle([{"height":"48px"}, {width: this.ganttWidth + 'px', top: this.offsetTop + 'px'}])
  }, [
    _createVNode(_component_timeline_summary, {
      affaire: this.affaire,
      type: this.type,
      "start-date-gantt": this.startDateGantt,
      "size-col": this.sizeCol
    }, null, 8, ["affaire", "type", "start-date-gantt", "size-col"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.costCenterGroupInJob, (costcenterGroup, index) => {
      return (_openBlock(), _createBlock(_component_timeline_cost_center, {
        key: costcenterGroup,
        position: index,
        affaire: this.affaire,
        "cost-center-group": costcenterGroup,
        type: this.type,
        "start-date-gantt": this.startDateGantt,
        "size-col": this.sizeCol
      }, null, 8, ["position", "affaire", "cost-center-group", "type", "start-date-gantt", "size-col"]))
    }), 128)),
    _createVNode(_component_timeline_deadline, {
      affaire: this.affaire,
      type: this.type,
      "start-date-gantt": this.startDateGantt,
      "gantt-width": this.ganttWidth,
      "size-col": this.sizeCol
    }, null, 8, ["affaire", "type", "start-date-gantt", "gantt-width", "size-col"])
  ], 4))
}