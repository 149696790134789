

import { defineComponent } from 'vue';
import GuiH1 from '@/components/gui/H1.vue';
import { mapState } from 'pinia';
import AffaireStore from '@/store/AffaireStore';

export default defineComponent({
  name: 'ListeAffaires',
  components: { GuiH1 },
  computed: {
    ...mapState(AffaireStore, ['affaires']),
  },
  methods: {
    removeAffaireFromState(index: number) {
      AffaireStore().removeAffaireFromIndex(index);
    },
  },
});
