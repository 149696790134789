
import { defineComponent } from 'vue';
import PlanningTable from '@/components/hebdo/PlanningTable.vue';
import DateHelper from '@/helper/DateHelper';
import PlanningTask from '@/components/hebdo/PlanningTask.vue';
import moment from 'moment/moment';
import Multiselect from '@vueform/multiselect';
import { mapState } from 'pinia';
import GanttStore from '@/store/GanttStore';
import CostCenterHelper from '@/helper/CostCenterHelper';
import HebdoViewStore from '@/store/HebdoViewStore';
import DatePicker from 'vue-datepicker-next';
import lodash from 'lodash';
import replannedTaskCountBus from '@/bus/replannedTaskCountBus';

export default defineComponent({
  name: 'HebdoPlanning',
  components: {
    PlanningTask,
    PlanningTable,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      weekMove: 0,
      langObject: {
        formatLocale: {
          firstDayOfWeek: 1,
          firstWeekContainsDate: 3,
        },
        monthBeforeYear: true,
      },
      isReplannedFilter: false,
      replannedCount: 0,
    };
  },
  computed: {
    ...mapState(HebdoViewStore, ['filter']),
    ...mapState(GanttStore, {
      options: 'options',
      costCenterGroupFilter: 'costCenterGroupFilter',
    }),
    getCostCenterFilterOption() {
      return CostCenterHelper.getCostCenterToShowGroups();
    },
    startDateFromMonday() {
      const date = new Date();
      return DateHelper.setToMonday(date);
    },
    getYear(): number {
      return moment(this.filter.week).year();
    },
    getWeekNumber(): number {
      return moment(this.filter.week).week();
    },
  },
  methods: {
    filterDateBackward() {
      this.filter.week = DateHelper.addDays(this.filter.week, -7);
      this.weekMove -= 1;
    },
    filterDateForward() {
      this.filter.week = DateHelper.addDays(this.filter.week, 7);
      this.weekMove += 1;
    },
    removeCostCenterFilter(value: string) {
      lodash.pull(this.filter.costCenter, value);
    },
    textBadgeColor(group: string) {
      return {
        [CostCenterHelper.getBackgroundColorFromGroup(group)]: true,
      };
    },
    getReplannedTaskCount(replannedNb: number) {
      this.replannedCount = replannedNb;
    },
  },
  mounted() {
    this.filter.week = this.startDateFromMonday;
    replannedTaskCountBus.on('replannedTaskCount', this.getReplannedTaskCount);
  },
  unmounted() {
    replannedTaskCountBus.off('replannedTaskCount', this.getReplannedTaskCount);
  },
});

