
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import AffaireStore from '@/store/AffaireStore';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import AffaireRank from '@/class/AffaireRank';
import UserStore from '@/store/UserStore';
import DraggableWindow from '@/components/draggable-window/DraggableWindow.vue';
import DetailTasksDelay from '@/components/dashboard/DetailTasksDelay.vue';

export default defineComponent({
  name: 'DashboardView',
  components: {
    DraggableWindow,
    DetailTasksDelay,
  },
  data() {
    return {
      timestamp: '',
      idInterval: -1,
      openTaskDelayDetail: false,
    };
  },
  computed: {
    ...mapState(AffaireStore, ['affaires']),
    ...mapState(UserStore, ['operators']),
    taskInDelay() {
      const tasks = new Array<RoutingWeekPlanning>();
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      const checkRoutingWeekPlanning = (rwp: RoutingWeekPlanning) => {
        const date = new Date(rwp.date);
        date.setHours(0, 0, 0, 0);
        if (date < now) {
          // On verifie que toutes les planifs operateurs on été pointé fini
          let finished = true;
          if (rwp.routingsDayPlanning.length === 0) {
            finished = false;
          } else {
            rwp.routingsDayPlanning.forEach((rdp) => {
              if (!finished) return;
              if (!rdp.isFinished) {
                finished = false;
              }
            });
          }
          if (!finished) {
            tasks.push(rwp as RoutingWeekPlanning);
          }
        }
      };

      const addRoutingFromRankChild = (rank: AffaireRank) => {
        rank.getRoutingsToShow().forEach((poste) => {
          poste.routingsWeekPlanning.forEach((rwp) => {
            checkRoutingWeekPlanning(rwp);
          });
        });

        rank.Childs.forEach((rankChild) => {
          addRoutingFromRankChild(rankChild);
        });
      };

      this.affaires.forEach((affaire) => {
        affaire.Ranks.forEach((rank) => {
          addRoutingFromRankChild(rank as AffaireRank);
        });

        // On ajoute les grouped routings
        affaire.regroupedRoutings.forEach((gr) => {
          gr.routingsWeekPlanning.forEach((rwp) => {
            checkRoutingWeekPlanning(rwp as RoutingWeekPlanning);
          });
        });
      });

      return tasks;
    },
  },
  mounted() {
    this.idInterval = setInterval(() => {
      this.timestamp = new Date().toLocaleTimeString();
    });
  },
  unmounted() {
    clearInterval(this.idInterval);
  },
});
