

import { defineComponent } from 'vue';
import DateHelper from '@/helper/DateHelper';
import { mapState } from 'pinia';
import AffaireStore from '@/store/AffaireStore';

export default defineComponent({
  name: 'CostCenterCol',
  props: {
    costCenterGroup: {
      type: String,
      required: true,
    },
    offsetNumber: {
      type: Number,
      required: true,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    totalHours: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      colSize: 92,
    };
  },
  computed: {
    ...mapState(AffaireStore, ['costCenterWeek']),
    colDate() {
      return DateHelper.addDaysWithoutWeekEnd(this.startDateGantt, this.offsetNumber * 5);
    },
    hoursCol() {
      const hour = this.costCenterWeek[`${this.costCenterGroup}-${this.offsetNumber}`];
      return hour === undefined ? '~' : String(Math.round(hour * 100) / 100);
    },
  },
});
