
import { defineComponent } from 'vue';
import Affaire from '@/class/Affaire';
import SideBarTasks from '@/components/gantt-shared/sidebar/SideBarTasks.vue';
import AffaireRank from '@/class/AffaireRank';
import DateHelper from '@/helper/DateHelper';
import GanttFilterCloseBus from '@/bus/GanttFilterCloseBus';
import moment from 'moment';
import ganttCloseAllAffaireBus from '@/bus/ganttCloseAllAffaireBus';
import BomHelper from '@/helper/BomHelper';

export default defineComponent({
  name: 'SideBarAffaire',
  components: { SideBarTasks },
  props: {
    affaire: {
      type: Affaire,
      required: true,
    },
  },
  methods: {
    updateShowGantt() {
      // eslint-disable-next-line vue/no-mutating-props
      this.affaire.showRanksInGantt = !this.affaire.showRanksInGantt;
      this.$emit('onToggle');
    },
    infosPastilleNomenclature(): string {
      const nomenclature = this.affaire.getStatusBom();

      return BomHelper.getInfosNomenclature(nomenclature);
    },
    infoPastilleDeadline(status: string): string {
      if (status === 'title') {
        const date = moment(this.affaire.InitDeadline, 'YYYY-MM-DD');
        const dateFormatted = date.format('DD/MM/YYYY');
        return `Deadline ${dateFormatted}`;
      }

      if (status === 'detail') {
        const date = new Date(this.affaire.InitDeadline);
        const now = new Date();
        const diff = DateHelper.getWeek(date) - DateHelper.getWeek(now) - 1;
        if (diff > 0) {
          return `${diff} semaine restants.`;
        }

        if (diff === 0) {
          return 'Fini cette semaine';
        }

        return `${0 - diff} semaine dépassée.`;
      }
      return '';
    },
    closeRank() {
      // eslint-disable-next-line vue/no-mutating-props
      this.affaire.showRanksInGantt = false;
    },
    openRank() {
      // eslint-disable-next-line vue/no-mutating-props
      this.affaire.showRanksInGantt = true;
    },
  },
  computed: {
    pastilleNomenclaturesColor() {
      return BomHelper.getStatusBomColor(this.affaire.getStatusBom());
    },
    stateBackgroundColor() {
      const date = new Date(this.affaire.InitDeadline);
      const now = new Date();

      const diff = DateHelper.getWeek(date) - DateHelper.getWeek(now) - 1;
      if (diff > 0) {
        return 'bg-emerald-500';
      }

      if (diff === 0) {
        return 'bg-yellow-500';
      }

      return 'bg-red-500';
    },
    delaiClient() {
      const date = this.affaire.InitDeadline.split('-');
      return `${date[2]}/${date[1]}/${date[0]}`;
    },
    allRanks() {
      const allRanks = new Array<AffaireRank>();
      const addRank = (rank: AffaireRank) => {
        allRanks.push(rank);
        rank.Childs.forEach((child) => {
          addRank(child);
        });
      };
      this.affaire.Ranks.forEach((rank) => {
        addRank(rank);
      });
      return allRanks;
    },
  },
  mounted() {
    this.$emit('side-bar-loaded');
    GanttFilterCloseBus.on('close', this.closeRank);
    ganttCloseAllAffaireBus.on('closeAll', this.closeRank);
    ganttCloseAllAffaireBus.on('openAll', this.openRank);
  },
  unmounted() {
    GanttFilterCloseBus.off('close', this.closeRank);
    ganttCloseAllAffaireBus.off('closeAll', this.closeRank);
    ganttCloseAllAffaireBus.off('openAll', this.openRank);
  },
});
