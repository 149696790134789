

import { defineComponent } from 'vue';
import GuiButtonPrimary from '@/components/gui/ButtonPrimary.vue';
import AffaireStore from '@/store/AffaireStore';
import GanttFilterCloseBus from '@/bus/GanttFilterCloseBus';
import lodash from 'lodash';
import { DatePicker } from 'v-calendar';
import ganttFilterInput from '@/bus/gantFilterInput';
import { mapState } from 'pinia';

export default defineComponent({
  name: 'FilterPlannificateur',
  components: {
    GuiButtonPrimary,
    DatePicker,
  },
  data() {
    return {
      filter: {
        inputPiece: undefined as string | undefined,
        inputAffaire: undefined as string | undefined,
        inputClient: undefined as string | undefined,
        inputDelaiStart: undefined as string | undefined,
        inputDelaiEnd: undefined as string | undefined,
      },
    };
  },
  computed: {
    ...mapState(AffaireStore, {
      inputFilter: 'inputFilter',
      isAssociate: 'isAssociateAffaire',
    }), // From vuex
  },
  methods: {
    sendFilter() {
      GanttFilterCloseBus.trigger('close', null);
      const tabFilter: Array<any> = [];
      const tabdate: Array<any> = ['inputDelai'];
      Object.entries(this.filter).forEach((entry) => {
        if ((entry[0] === 'inputDelaiStart' || entry[0] === 'inputDelaiEnd') && entry[1]) {
          tabdate.push(entry[1]);
          if (tabdate.length === 2) {
            tabFilter.push(tabdate);
          }
        } else {
          tabFilter.push(entry);
        }
      });

      AffaireStore().saveFilter(tabFilter);
    },
    effacer() {
      // GanttFilterCloseBus.trigger('close', null);
      this.filter.inputPiece = undefined;
      this.filter.inputAffaire = undefined;
      this.filter.inputClient = undefined;
      this.filter.inputDelaiStart = undefined;
      this.filter.inputDelaiEnd = undefined;
    },
    clearFilter() {
      AffaireStore().resetFilter();
    },
    addInputAffaire(inputAffaire: string) {
      this.filter.inputAffaire = inputAffaire;
    },
    format(date: Date) {
      const day = (0 + date.getDate().toString()).slice(-2);
      const month = (0 + (date.getMonth() + 1).toString()).slice(-2);
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    loadFilter(filterInput: Array<any>) {
      filterInput.forEach((element: any) => {
        switch (element[0]) {
          case 'inputPiece':
            this.filter.inputPiece = element[1];
            break;
          case 'inputAffaire':
            this.filter.inputAffaire = element[1];
            break;
          case 'inputClient':
            this.filter.inputClient = element[1];
            break;
          case 'inputDelai':
            this.filter.inputDelaiStart = element[1];
            this.filter.inputDelaiEnd = element[2];
            break;
          default:
            break;
        }
      });
    },
  },
  mounted() {
    if (!lodash.isEmpty(this.inputFilter)) {
      this.loadFilter(this.inputFilter);
    }
    GanttFilterCloseBus.on('clearFilter', this.effacer);
    ganttFilterInput.on('inputAffaire', this.addInputAffaire);
  },
  unmounted() {
    GanttFilterCloseBus.off('clearFilter', this.effacer);
    ganttFilterInput.off('inputAffaire', this.addInputAffaire);
  },
});
