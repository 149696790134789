

import { defineComponent } from 'vue';
import GuiH1 from '@/components/gui/H1.vue';
import CostCenterHelper from '@/helper/CostCenterHelper';
import CostCenter from '@/class/CostCenter';
import { mapState } from 'pinia';
import AffaireStore from '@/store/AffaireStore';
import TimeHelper from '@/helper/TimeHelper';

export default defineComponent({
  name: 'TotalizerTime',
  components: { GuiH1 },
  computed: {
    costCentersToShow() {
      return CostCenterHelper.getCostCenterToShow();
    },
    ...mapState(AffaireStore, ['affaires']),
  },
  data() {
    return {
      costCentersCapacity: {} as {[key: string]: number},
      costCentersCharge: {} as {[key: string]: number},
    };
  },
  watch: {
    affaires: {
      deep: true,
      handler() {
        this.updateTimers();
      },
    },
  },
  methods: {
    getCostCenterCapacity(costCenter: CostCenter): number {
      return Number(TimeHelper.fixFormatHoursMinute(
        this.costCentersCapacity[costCenter.Code],
      ));
    },
    getCostCenterCharge(costCenter: CostCenter): number {
      const totalNumber = Number(TimeHelper.fixFormatHoursMinute(
        this.costCentersCharge[costCenter.Code],
      ));

      if (Number.isNaN(totalNumber)) {
        return 0;
      }

      return Number(TimeHelper.fixFormatHoursMinute(
        this.costCentersCharge[costCenter.Code],
      ));
    },
    updateTimers() : void {
      this.costCentersCharge = {};
      this.costCentersCapacity = {};
      this.costCentersToShow.forEach((costCenter) => {
        this.costCentersCapacity[costCenter.Code] = CostCenterHelper
          .getCostCenterCapacity(costCenter);
        this.costCentersCharge[costCenter.Code] = CostCenterHelper
          .getCostCenterCharge(costCenter);
      });
    },
    getBackgroundColorCharge(costCenter: CostCenter): {[key: string]: boolean} {
      const difference = this.getCostCenterCapacity(costCenter)
        - this.getCostCenterCharge(costCenter);
      if (difference > 10) {
        return { 'bg-blue-300': true };
      }
      if (difference >= 0) {
        return { 'bg-green-300': true };
      }
      if (difference < -10) {
        return { 'bg-red-300': true };
      }
      return { 'bg-yellow-300': true };
    },
  },
  mounted() {
    this.updateTimers();
  },
});
