
import { defineComponent } from 'vue';
import DateHelper from '@/helper/DateHelper';

export default defineComponent({
  name: 'YearWeekCol',
  props: {
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    dayToAdd: {
      type: Number,
      default: () => 0,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    startDateFromMonday() {
      return DateHelper.addDays(this.startDate, this.dayToAdd);
    },
    getYear() {
      return this.startDateFromMonday.getFullYear();
    },
    isFirstDayOfYear() {
      return this.getWeekNumber(this.startDateFromMonday) === 1;
    },
    isLastDayOfYear() {
      return this.getWeekNumber(this.startDateFromMonday) === 52;
    },
  },
  methods: {
    weekName: DateHelper.weekName,
    setToMonday: DateHelper.setToMonday,
    addDays: DateHelper.addDays,
    getWeekNumber(d: Date) {
      const date = new Date(d);
      // ISO week date weeks start on Monday, so correct the day number
      const nDay = (date.getDay() + 6) % 7;

      // ISO 8601 states that week 1 is the week with the first Thursday of that year
      // Set the target date to the Thursday in the target week
      date.setDate(date.getDate() - nDay + 3);

      // Store the millisecond value of the target date
      const n1stThursday = date.valueOf();

      // Set the target to the first Thursday of the year
      // First, set the target to January 1st
      date.setMonth(0, 1);

      // Not a Thursday? Correct the date to the next Thursday
      if (date.getDay() !== 4) {
        // eslint-disable-next-line no-mixed-operators
        date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
      }

      // The week number is the number of weeks between the first Thursday of the year
      // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
      return 1 + Math.ceil((n1stThursday - date.valueOf()) / 604800000);
    },
    oddYear() {
      return (this.startDateFromMonday.getFullYear() % 2) === 0;
    },
  },
});
