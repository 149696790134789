
import { defineComponent, PropType } from 'vue';
import RankRouting from '@/class/RankRouting';
import DateHelper from '@/helper/DateHelper';
import ganttScrollEvent from '@/bus/ganttScrollEvent';
import ganttMultiPostMove from '@/bus/ganttMultiPostMove';
import ganttRoutingUpdatingBus from '@/bus/gantRoutingUpdatingBus';
import CostCenterHelper from '@/helper/CostCenterHelper';
import GanttStore from '@/store/GanttStore';
import BaseRouting from '@/class/BaseRouting';
import GroupedRouting from '@/class/GroupedRouting';
import weekHourDistributorBus from '@/bus/weekHourDistributorBus';
import TimeHelper from '@/helper/TimeHelper';
import deadlineUpdateBus from '@/bus/deadlineUpdateBus';
import { mapState } from 'pinia';
import UserAuthStore from '@/store/UserAuthStore';

export default defineComponent({
  name: 'GanttValuePoste',
  data() {
    return {
      grab: {
        clientX: 0,
        newPosX: 0,
        isMooving: false,
        offsetWheel: 0,
        isResizingEnd: false,
        isResizingStart: false,
        lastDayDragging: 0,
      },
      poste: this.routings[this.index] as BaseRouting,
      groupMoving: false,
      mainGroupMoving: false,
      readOnly: true,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    routings: {
      type: Object as PropType<RankRouting[] | GroupedRouting[]>,
      required: true,
    },
    startDateGantt: {
      type: Date,
      required: true,
    },
    endDateGantt: {
      type: Date,
      required: true,
    },
    sizeCol: {
      type: Number,
      default: () => 92,
    },
  },
  computed: {
    ...mapState(
      UserAuthStore,
      { user: 'user' },
    ),
    TimeHelper() {
      return TimeHelper;
    },
    manuTime() {
      return Math.round(
        this.poste.weeksHoursDistributor
          .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        * 100,
      ) / 100;
    },
    colSize() {
      return this.type === 'DAY' ? 72 : this.sizeCol;
    },
    color() {
      return this.poste instanceof RankRouting
        ? this.poste.CostCenterObject?.getBackgroundColor() ?? 'bg-black'
        : `bg-${CostCenterHelper.getColorFromGroup((this.poste as GroupedRouting).getGroup())}-500`;
    },
    offsetTop: () => 1,
    getDaysDragging() {
      return Math.round((this.grab.newPosX - this.grab.clientX) / this.colSize);
    },
    leftPos() {
      if (this.type === 'WEEK') {
        return (DateHelper.getWeekDatesCount(
          this.startDateGantt,
          this.poste.getStartDate(),
        ) - 1) * this.colSize;
      }
      return (DateHelper.getBusinessDatesCount(
        this.startDateGantt,
        this.poste.getStartDate(),
      ) - 1) * this.colSize;
    },
    sizeWidth() {
      if (this.type === 'WEEK') {
        return DateHelper.getWeekDatesCount(
          DateHelper.max(this.poste.getStartDate(), this.startDateGantt),
          this.poste.getEndDate(),
        ) * this.colSize;
      }
      return DateHelper.getBusinessDatesCount(
        DateHelper.max(this.poste.getStartDate(), this.startDateGantt),
        this.poste.getEndDate(),
      ) * this.colSize;
    },
  },
  methods: {
    movingGroup(moveNumber: number) {
      if (!this.mainGroupMoving) {
        if (!this.grab.isMooving) {
          ganttMultiPostMove.on('stopDrag', this.groupMovingStop);
        }
        this.grab.lastDayDragging -= moveNumber;
        this.grab.isMooving = true;
        this.updateValuePoste();
      }
    },
    groupMovingStop() {
      this.stopDrag();
    },
    groupMovingReset(status: number) {
      this.stopDrag();
      ganttMultiPostMove.trigger('stopDrag', status);
      this.mainGroupMoving = false;
      this.groupMoving = false;
      ganttMultiPostMove.off('moving', this.movingGroup);
      ganttMultiPostMove.off('stopDrag', this.groupMovingStop);
    },
    openWindowWeekDistributor(e: MouseEvent) {
      e.preventDefault();
      if (this.readOnly) {
        return;
      }

      weekHourDistributorBus.trigger('open', this.poste as RankRouting);
    },
    startResizeEnd(event: MouseEvent) {
      if (this.readOnly) {
        return;
      }

      this.grab.clientX = event.clientX;
      this.grab.newPosX = event.clientX;
      this.grab.offsetWheel = 0;
      this.grab.isResizingEnd = true;
      document.onmousemove = this.moveDrag;
      document.onmouseup = this.stopDrag;
      this.emitIsEditingGantt();
    },
    startResizeStart(event: MouseEvent) {
      if (this.readOnly) {
        return;
      }

      this.grab.clientX = event.clientX;
      this.grab.newPosX = event.clientX;
      this.grab.offsetWheel = 0;
      this.grab.isResizingStart = true;
      document.onmousemove = this.moveDrag;
      document.onmouseup = this.stopDrag;
      this.emitIsEditingGantt();
    },
    startDrag(event: MouseEvent) {
      if (this.readOnly) {
        return;
      }

      // event.button = 2: Right Click
      if (event.button !== 2) {
        if (event.ctrlKey) {
          this.mainGroupMoving = true;

          if (!this.groupMoving) {
            this.groupMoving = true;
            ganttMultiPostMove.on('moving', this.movingGroup);
          }
        }

        this.grab.clientX = event.clientX;
        this.grab.newPosX = event.clientX;
        this.grab.offsetWheel = 0;
        this.grab.isMooving = true;
        document.onmousemove = this.moveDrag;
        document.onmouseup = this.stopDrag;
        this.emitIsEditingGantt();
      }
    },
    moveDrag(event: MouseEvent) {
      if (this.grab.isMooving || this.grab.isResizingEnd || this.grab.isResizingStart) {
        this.grab.newPosX = event.clientX + this.grab.offsetWheel;
        this.updateValuePoste();
      }
    },
    updateValuePoste() {
      const dayOffsetDragging = this.getDaysDragging - this.grab.lastDayDragging;
      this.grab.lastDayDragging = this.getDaysDragging;
      if (dayOffsetDragging === 0) return;

      if (this.mainGroupMoving) {
        ganttMultiPostMove.trigger('moving', dayOffsetDragging);
      }

      // Calcul date minimum en fonction des enfants
      const minDate = this.startDateGantt;

      // Calcul date maximum en fonction du parent
      const maxDate = this.endDateGantt;

      if (this.grab.isMooving) {
        this.poste.handleRouting(
          minDate,
          maxDate,
          this.routings,
          dayOffsetDragging * (this.type === 'WEEK' ? 5 : 1),
          this.index,
          this.type === 'WEEK',
          this.type === 'DAY',
        );
      }
      if (this.grab.isResizingEnd) {
        this.poste.moveEndDate(
          minDate,
          maxDate,
          this.routings,
          dayOffsetDragging * (this.type === 'WEEK' ? 5 : 1),
          this.index,
          this.type === 'WEEK',
        );
      }
      if (this.grab.isResizingStart) {
        this.poste.moveStartDate(
          minDate,
          maxDate,
          this.routings,
          dayOffsetDragging * (this.type === 'WEEK' ? 5 : 1),
          this.index,
          this.type === 'WEEK',
        );
      }

      // Jit rendering
      const optionsGantt = GanttStore().options;
      if (optionsGantt.showCostCenter && optionsGantt.JITRendering) {
        let offsetMin;
        let offsetMax;
        if (this.type === 'WEEK') {
          offsetMin = Math.ceil(CostCenterHelper
            .getNumberDateOffsetCostCenterDaily(
              this.startDateGantt,
              DateHelper.setToMonday(minDate),
            )
            / 5);
          offsetMax = Math.ceil(CostCenterHelper
            .getNumberDateOffsetCostCenterDaily(
              this.startDateGantt,
              DateHelper.setToFriday(maxDate),
            )
            / 5);
        } else {
          offsetMin = CostCenterHelper
            .getNumberDateOffsetCostCenterDaily(this.startDateGantt, minDate);
          offsetMax = CostCenterHelper
            .getNumberDateOffsetCostCenterDaily(this.startDateGantt, maxDate);
        }

        for (let i = offsetMin; i <= offsetMax; i += 1) {
          this.routings.forEach((routing) => {
            let group: string;
            if (routing instanceof RankRouting) {
              if (routing.CostCenterObject === undefined) return;
              group = routing.CostCenterObject.Group ?? '';
            } else { // Not checking instanceof cause only 2 inheritors
              group = routing.getGroup();
            }
            if (group === '') return;

            if (this.type === 'WEEK') {
              CostCenterHelper.addToQueueCostCenterWeekly(
                group,
                i,
                this.startDateGantt,
              );
            } else {
              CostCenterHelper.addToQueueCostCenterDaily(
                group,
                i,
                this.startDateGantt,
              );
            }
          });
        }
      }
    },
    onWheelScroll(event: WheelEvent) {
      if (this.grab.isMooving || this.grab.isResizingEnd || this.grab.isResizingStart) {
        const scrollMove = event.deltaY > 0 ? this.sizeCol : -this.sizeCol;
        this.grab.offsetWheel += scrollMove;
        this.grab.newPosX = event.clientX + this.grab.offsetWheel;
      }
    },
    stopDrag() {
      this.updateDeadline();

      if (this.mainGroupMoving) {
        ganttMultiPostMove.trigger('stopDrag', 0);
        this.mainGroupMoving = false;
      }
      ganttMultiPostMove.off('stopDrag', this.groupMovingStop);
      this.grab.isMooving = false;
      this.grab.isResizingStart = false;
      this.grab.isResizingEnd = false;
      this.grab.lastDayDragging = 0;
      document.onmousemove = null;
      document.onmouseup = null;
      this.emitStopEditingGantt();

      // Persisting all routings date
      this.routings.forEach((routing) => {
        routing.persistDate();
      });
    },
    updateDeadline() {
      if (this.routings[0].Affaire !== undefined && this.routings[0].Affaire.Job !== undefined) {
        deadlineUpdateBus.trigger('update', this.routings[0].Affaire.Job);
      }
    },
    emitIsEditingGantt() {
      ganttRoutingUpdatingBus.trigger('isEditing', true);
    },
    emitStopEditingGantt() {
      ganttRoutingUpdatingBus.trigger('isEditing', false);
    },
  },
  mounted() {
    ganttScrollEvent.on('scroll', this.onWheelScroll);
    ganttMultiPostMove.on('ctrRelease', this.groupMovingReset);

    const user = this.user;
    if (user === undefined) {
      return;
    }

    const role = user.role;
    if (role === 'admin' || role === 'planificateur') {
      this.readOnly = false;
    }
  },
  unmounted() {
    ganttScrollEvent.off('scroll', this.onWheelScroll);
    ganttMultiPostMove.off('ctrRelease', this.groupMovingReset);
  },
});

