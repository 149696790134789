
import { defineComponent, PropType } from 'vue';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import DateHelper from '@/helper/DateHelper';
import BaseRouting from '@/class/BaseRouting';
import RankRouting from '@/class/RankRouting';
import GroupedRouting from '@/class/GroupedRouting';
import routingWeekPlaningEvent from '@/bus/routingWeekPanningEvent';
import GanttResizeWindow from '@/bus/ganttResizeWindow';

export default defineComponent({
  name: 'DetailTasksDelay',
  data() {
    return {
      width: 1000,
      shifterHeight: 140,
    };
  },
  props: {
    tasks: {
      type: Object as PropType<Array<RoutingWeekPlanning>>,
      required: true,
    },
  },
  methods: {
    openWeekPlanningDetail(rwp: RoutingWeekPlanning) {
      routingWeekPlaningEvent.trigger('showDetail', rwp);
    },
    getRoutingType(baseRouting: BaseRouting) {
      if (baseRouting instanceof RankRouting) {
        return 'RankRouting';
      }
      if (baseRouting instanceof GroupedRouting) {
        return 'GroupedRouting';
      }

      return 'BaseRouting';
    },
    setWith(arraySize: Array<number>) {
      this.width = arraySize[1];
    },
  },
  computed: {
    tasksDelaysDays() {
      const data = {} as {[key: string]: Array<RoutingWeekPlanning>};
      data.minusOne = [];
      data.minusTwo = [];

      const dateMinusOne = DateHelper.addDays(new Date(), -1);
      dateMinusOne.setHours(0, 0, 0, 0);

      const dateMinusTwo = DateHelper.addDays(new Date(), -2);
      dateMinusTwo.setHours(0, 0, 0, 0);
      this.tasks.forEach((rwp) => {
        const rwpDateZeroHours = new Date(rwp.date);
        rwpDateZeroHours.setHours(0, 0, 0, 0);
        if (rwpDateZeroHours.getTime() === dateMinusOne.getTime()) {
          data.minusOne.push(rwp);
        }
        if (rwpDateZeroHours.getTime() === dateMinusTwo.getTime()) {
          data.minusTwo.push(rwp);
        }
      });
      return data;
    },
    headerGap() {
      return 140;
    },
    computedClass() {
      if (this.width < 488) {
        return 'pt-[420px]';
      }

      if (this.width < 733) {
        return 'pt-[285px]';
      }

      return 'pt-[145px]';
    },
  },
  mounted() {
    GanttResizeWindow.on('resize', this.setWith);
  },
  unmounted() {
    GanttResizeWindow.off('resize', this.setWith);
  },
});
