

import { defineComponent } from 'vue';
import HelperBox from '@/components/gui/HelperBox.vue';
import WPRouting from '@/components/hebdo/costCenter/WPRouting.vue';
import BaseRouting from '@/class/BaseRouting';
import RoutingHelper from '@/helper/RoutingHelper';
import { mapState } from 'pinia';
import HebdoViewStore from '@/store/HebdoViewStore';
import HebdoGroupStore from '@/store/HebdoGroupStore';
import CostCenterHelper from '@/helper/CostCenterHelper';
import lodash from 'lodash';
import TimeHelper from '@/helper/TimeHelper';
import replannedTaskCountBus from '@/bus/replannedTaskCountBus';

export default defineComponent({
  components: { WPRouting, HelperBox },
  props: {
    isReplannedFilter: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(HebdoViewStore, ['filter']),
    ...mapState(HebdoGroupStore, ['group']),
    costCenterHelper: () => CostCenterHelper,
    routingsAvailable(): {[key: string]: Array<BaseRouting>} {
      const routingsCostCenters: any = {};

      const filterRouting = (r: BaseRouting) => {
        if (r.routingsWeekPlanning.length === 0) {
          return true;
        }
        return r.getCurrentWeekMinutesDefined() < TimeHelper.formattingHoursMinuteToMinute(r.ManuTime);
      };

      this.filter.costCenter.forEach((group: string) => {
        let routings = RoutingHelper
          .getRoutingByWeekAndCostCenter(this.filter.week, group);
        routings = routings.filter(filterRouting);
        routingsCostCenters[group] = routings;
      });

      return routingsCostCenters;
    },
    routingsAvailableByJobGroup(): {[group: string]: {[key: string]: Array<BaseRouting>} } {
      const result: {[group: string]: {[key: string]: Array<BaseRouting>} } = {};
      let replannedTaskCount = 0;

      lodash.forEach(this.routingsAvailable, (routings, group) => {
        result[group] = {};
        routings.forEach((r) => {
          const affaire = r.Affaire;
          if (affaire === undefined) return;
          if (this.isReplannedFilter) {
            if (!r.isReplanned) return;
          }
          if (!Object.keys(result[group]).includes(`${affaire.Job} : ${affaire.Designation1}`)) {
            result[group][`${affaire.Job} : ${affaire.Designation1}`] = [];
          }
          result[group][`${affaire.Job} : ${affaire.Designation1}`].push(r);
          if (r.isReplanned) {
            replannedTaskCount += 1;
          }
        });
      });
      replannedTaskCountBus.trigger('replannedTaskCount', replannedTaskCount);
      return result;
    },
  },
  methods: {
    resetGroup() {
      HebdoGroupStore().group.splice(0);
    },
  },
});
