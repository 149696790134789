
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonVueSelector',
  props: {
    isSelected: {
      type: Boolean,
      default: () => false,
    },
  },
});
