
import { defineComponent, PropType } from 'vue';
import AffaireRank from '@/class/AffaireRank';
import GanttTaskUpdateShowBus from '@/bus/ganttTaskUpdateShowBus';
import ganttWirelessCollapseRank from '@/bus/ganttWirelessCollapseRank';
import PlanificateurEventBus from '@/bus/planificateurEventBus';
import { mapState } from 'pinia';
import GanttStore from '@/store/GanttStore';
import Affaire from '@/class/Affaire';
import RankHelper from '@/helper/RankHelper';
import ganttWirelessCollapseGroupedRank from '@/bus/ganttWirelessCollapseGroupedRank';
import CostCenterHelper from '@/helper/CostCenterHelper';
import BaseRouting from '@/class/BaseRouting';
import RankRouting from '@/class/RankRouting';
import GroupedRouting from '@/class/GroupedRouting';
import BomHelper from '@/helper/BomHelper';
import RankClockingState from '@/enum/RankClockingState';
import BomGroupStatus from '@/enum/BomGroupStatus';

export default defineComponent({
  name: 'SideBarTasks',
  props: {
    tasks: {
      type: Object as PropType<Array<AffaireRank>>,
      required: true,
    },
    job: {
      type: Affaire,
      required: true,
    },
    showAll: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(GanttStore, {
      options: 'options',
      weeksFilter: 'weeksFilter',
    }),
    tasksFilteredWeekAndCostCenter() {
      return this.tasks
        .filter((rank) => rank.getFilteredRoutings().length > 0);
    },
    tasksGroupedFilteredWeekAndCostCenter() {
      return this.job.getFilteredGroupRoutines();
    },
    costCentersGroup() {
      return CostCenterHelper.getCostCenterToShowGroups();
    },
    ...mapState(GanttStore, ['costCenterGroupFilter']),
  },
  data() {
    return {
      postesToggle: {} as {[key: number | string]: boolean},
    };
  },
  methods: {
    infosPastilleNomenclature(routing: RankRouting): string {
      const nomenclature = routing.getStatusBom();

      return BomHelper.getInfosNomenclature(nomenclature);
    },
    infosPastilleRankClass(routing: RankRouting): string {
      return routing.getStatusTaskText();
    },
    statusRankClass(routing: RankRouting) {
      switch (routing.getStatusTask()) {
        case RankClockingState.FINISHED:
          return '✅️';
        case RankClockingState.STARTED:
        case RankClockingState.IN_PROGRESS:
          return '⏳';
        case RankClockingState.REPLANNED:
          return '📝';
        case RankClockingState.NOT_STARTED:
        default:
          return '❌️';
      }
    },
    statusNomenclature(routing: RankRouting): string {
      const status: number = routing.getStatusBom();
      switch (status) {
        case BomGroupStatus.RECEIVED:
          return '✅️';
        case BomGroupStatus.PARTIALLY_ORDERED:
        case BomGroupStatus.NO_ORDER_DELAY:
          return '⚠️';
        case BomGroupStatus.NO_ORDER:
          return '❌️';
        case BomGroupStatus.UNDEFINED:
        default:
          return '';
      }
    },
    color(poste: BaseRouting) {
      return poste instanceof RankRouting
        ? poste.CostCenterObject?.getBackgroundColor() ?? 'bg-black'
        : `bg-${CostCenterHelper.getColorFromGroup((poste as GroupedRouting).getGroup())}-500`;
    },
    isFirstRank(index: number) {
      if (index === 0) return true;
      const currentRank = this.tasks[index];
      if (currentRank.Parent === undefined) {
        return false;
      }
      return currentRank.Parent.Childs.indexOf(currentRank) === 0;
    },
    getColorLevel(level: number) {
      return RankHelper.getColorLevel(level);
    },
    getColorTextLevel(level: number) {
      return RankHelper.getColorTextLevel(level);
    },
    showRankDetails(rank: AffaireRank) {
      PlanificateurEventBus.trigger('showDetail', rank);
    },
    updateTaskShow(task: AffaireRank, shouldShow: boolean) {
      this.$nextTick(() => {
        if (this.tasksFilteredWeekAndCostCenter.find(
          (t) => t.IdJobFile === task.IdJobFile,
        ) === undefined) {
          return;
        }
        const div = this.$refs[`containerTask${task.IdJobFile}`] as Array<HTMLDivElement>;

        GanttTaskUpdateShowBus.trigger('update', {
          task,
          refMenu: div[0],
          shouldShow,
          groupedRoutings: undefined,
        });
      });
    },
    updateTaskShowGrouped(shouldShow: boolean) {
      this.$nextTick(() => {
        if (this.$refs.containerTaskGrouped === undefined) {
          return;
        }
        const div = this.$refs.containerTaskGrouped as HTMLDivElement;

        GanttTaskUpdateShowBus.trigger('update', {
          task: undefined,
          refMenu: div,
          shouldShow,
          groupedRoutings: this.job,
        });
      });
    },
    toggleCollapseRank(rank: AffaireRank) {
      const newState = !this.postesToggle[rank.IdJobFile];
      this.postesToggle[rank.IdJobFile] = newState;
      this.updateTaskShow(rank, newState);
    },
    toggleCollapseRankIndex(index: number | string) {
      const newState = !this.postesToggle[index];
      this.postesToggle[index] = newState;
      this.updateTaskShowGrouped(newState);
    },
    toggleGroupedRank(affaire: Affaire | undefined = undefined) {
      if (affaire !== undefined && affaire.Job !== this.job.Job) {
        return;
      }
      this.toggleCollapseRankIndex(`Grouped${this.job.Job}`);
    },
    textBadgeColor(group: string) {
      return {
        [CostCenterHelper.getBackgroundColorFromGroup(group)]: true,
      };
    },
    isGroupFiltered(group: string) {
      return this.costCenterGroupFilter.includes(group);
    },
    toggleFilterCostCenter(group: string) {
      if (this.isGroupFiltered(group)) {
        this.costCenterGroupFilter.splice(this.costCenterGroupFilter.indexOf(group), 1);
      } else {
        this.costCenterGroupFilter.push(group);
      }
    },
  },
  watch: {
    tasksFilteredWeekAndCostCenter: function tasksFilteredWeek() {
      // on efface tous les routings
      GanttTaskUpdateShowBus.trigger('resetAllRanks', null);
      this.tasksFilteredWeekAndCostCenter.forEach((t) => {
        // === just to be sure it's boolean
        // Puis on réaffiche ceux qui sont dans le filtre, en les réouvrant
        // si ils etaient déjà ouverts
        this.updateTaskShow(t, this.postesToggle[t.IdJobFile]);
      });
    },
    tasksGroupedFilteredWeekAndCostCenter: function tasksGroupedFilteredWeekAndCostCenter() {
      // on efface tous les routings
      GanttTaskUpdateShowBus.trigger('resetAllRanks', null);
      this.updateTaskShowGrouped(this.postesToggle[`Grouped${this.job.Job}`]);
    },
  },
  mounted() {
    this.tasks.forEach((task) => {
      this.updateTaskShow(task, false);
    });
    this.updateTaskShowGrouped(false);
    ganttWirelessCollapseRank.on('collapse', this.toggleCollapseRank);
    ganttWirelessCollapseGroupedRank.on('collapse', this.toggleGroupedRank);

    if (this.showAll) {
      this.toggleGroupedRank();
      this.tasks.forEach((rank) => {
        this.toggleCollapseRank(rank);
      });
    }
  },
  unmounted() {
    ganttWirelessCollapseRank.off('collapse', this.toggleCollapseRank);
    ganttWirelessCollapseGroupedRank.off('collapse', this.toggleGroupedRank);
  },
});
