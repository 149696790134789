
import { defineComponent } from 'vue';
import Affaire from '@/class/Affaire';
import Planificateur from '@/components/planificateur/Planificateur.vue';
import PlanificateurLineWithChildren from '@/components/planificateur/LineWithChildren.vue';
import TreeView from '@/components/tree-view/TreeView.vue';

export default defineComponent({
  name: 'PlanificateurRow',
  components: { Planificateur, PlanificateurLineWithChildren, TreeView },
  props: {
    job: {
      type: Affaire,
      required: true,
    },
  },
  data() {
    return {
      showTree: false,
    };
  },
});
