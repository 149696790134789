
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import UserStore from '@/store/UserStore';
import moment from 'moment';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import TimeHelper from '@/helper/TimeHelper';
import RankRouting from '@/class/RankRouting';
import GroupedRouting from '@/class/GroupedRouting';
import BaseRouting from '@/class/BaseRouting';
import CostCenterHelper from '@/helper/CostCenterHelper';
import RoutingHelper from '@/helper/RoutingHelper';
import DateHelper from '@/helper/DateHelper';
import HebdoViewStore from '@/store/HebdoViewStore';
import HebdoGroupStore from '@/store/HebdoGroupStore';
import routingWeekPanningEvent from '@/bus/routingWeekPanningEvent';
import Notiflix from 'notiflix';
import WeeklyPlanningMoveEvent from '@/bus/weeklyPlanningMoveEvent';
import { WeeklyPlanningEventBusType } from '@/type/WeeklyPlanningEventBusType';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';
import UserEntity from '@/class/UserEntity';
import lodash, { clone } from 'lodash';
import UserHelper from '@/helper/UserHelper';
import MainEndpoint from '@/endpoints/main';
import { AxiosError } from 'axios';

export default defineComponent({
  name: 'PlanningTable',
  props: {
    startDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      nbColumn: 5,
      currentDragRouting: undefined as undefined | BaseRouting,
      colorCols: {} as any,
      colHover: undefined as undefined | number,
      rowHover: undefined as undefined | string,
      daysRouting: {} as any,
      isOperaterAbsence: {} as boolean,
      definedTime: {} as any,
      filterText: '',
      operatorsPlanings: {} as any,
      holiday: [] as Array<boolean> | undefined,
      costCenterAddTask: undefined as undefined | string,
      taskMinutesEdit: undefined as undefined | number,
    };
  },
  computed: {
    TimeHelper() {
      return TimeHelper;
    },
    ...mapState(UserStore, ['operators']),
    ...mapState(HebdoViewStore, ['filter']),
    ...mapState(HebdoGroupStore, ['groupIsMooving', 'group']),
    filteredOperators(): Array<UserEntity> {
      if (this.filterText.length === 0) {
        return this.operators as Array<UserEntity>;
      }

      return (this.operators as Array<UserEntity>).filter((operator) => {
        const textOperator = `${operator.FirstName} ${operator.Name}`;
        return textOperator.toLowerCase()
          .includes(this.filterText.toLowerCase());
      });
    },
    routingsAvailable(): Array<BaseRouting> {
      return RoutingHelper
        .getRoutingByWeek(
          this.filter.week,
        );
    },
    colDate(): Array<Date> {
      const monday = DateHelper.setToMonday(
        this.filter.week,
      );
      monday.setHours(0, 0, 0, 0);
      const dates = new Array<Date>();
      for (let i = 0; i < this.nbColumn; i += 1) {
        const baseDate = new Date(monday);
        baseDate.setHours(24 * i);
        dates.push(baseDate);
      }
      return dates;
    },
    sortOperator(): Array<UserEntity> {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.filteredOperators.sort((a, b) => {
        const aTask = this.operatorsPlanings[a.User];
        const bTask = this.operatorsPlanings[b.User];

        if (lodash.isUndefined(aTask) && lodash.isUndefined(bTask)) {
          return 0;
        }

        if (lodash.isUndefined(aTask)) {
          return 1;
        }

        if (lodash.isUndefined(bTask)) {
          return -1;
        }

        if (aTask.totalTime === bTask.totalTime) {
          return 0;
        }

        if (aTask.totalTime <= 0 || lodash.isUndefined(aTask.defaultTask)) {
          return 1;
        }

        if (bTask.totalTime <= 0 || lodash.isUndefined(bTask.defaultTask)) {
          return -1;
        }

        return CostCenterHelper.sortCostCenter(aTask.defaultTask, bTask.defaultTask);
      });
    },
  },
  methods: {
    firstNameLowerCase(firstName: string): string {
      let newName = firstName.toLowerCase();
      newName = firstName.charAt(0).toUpperCase() + newName.slice(1);
      return newName;
    },
    getLongestDefaultCostCenter(costCenter: { [key: string]: number }): string {
      const firstEntry = lodash.first(lodash.toPairs(costCenter));
      if (firstEntry === undefined || firstEntry[1] === undefined) return '';

      let name = firstEntry[0];
      let longest = firstEntry[1];

      lodash.forEach(costCenter, (value, key) => {
        if (value > longest) {
          longest = value;
          name = key;
        }
      });

      return name;
    },
    totalTimeByDay(operator: string, i: number) {
      let totalTime = 0;

      this.daysRouting[operator][i].forEach((dayRouting: any) => {
        totalTime += dayRouting.minutes;
      });

      return totalTime;
    },
    totalTimeAvailableByDay(operator: string, i: number) {
      let totalTimeAvailable = (i === 4) ? 5 : 8;

      if (this.operatorsPlanings[operator] === undefined || this.operatorsPlanings[operator].dayAbsence === undefined || this.operatorsPlanings[operator].dayAbsence[i + 1] === undefined) {
        return totalTimeAvailable;
      }

      totalTimeAvailable -= this.operatorsPlanings[operator].dayAbsence[i + 1];

      return totalTimeAvailable;
    },
    totalTimeDayColorStatus(operator: string, i: number) {
      const totalTimeDefine = this.totalTimeByDay(operator, i);
      const totalTimeAvailable = this.totalTimeAvailableByDay(operator, i) * 60;

      if (totalTimeDefine > totalTimeAvailable) {
        return 'bg-red-200';
      }

      if (totalTimeDefine === totalTimeAvailable) {
        return 'bg-green-200';
      }
      return '';
    },
    totalTimeColorStatus(operator: string) {
      const totalTimeDefine = this.definedTime[operator].totalTime * 60;

      if (this.definedTime[operator].totalTime > totalTimeDefine && totalTimeDefine !== 0) {
        return 'bg-red-200';
      }

      if (this.definedTime[operator].totalTime === totalTimeDefine && totalTimeDefine !== 0) {
        return 'bg-green-200';
      }

      return '';
    },
    isWeekAbsence(operator: string): boolean {
      if (this.operatorsPlanings[operator] === undefined) {
        return false;
      }

      return this.operatorsPlanings[operator].weekAbsence || this.operatorsPlanings[operator].totalTime === 0;
    },
    setOperator() {
      const year = moment(this.filter.week).year();
      const week = moment(this.filter.week).week();

      MainEndpoint.getAxiosInstance().get(`user-absence/get-week/${year}/${week}`).then((response) => {
        this.holiday = response.data.holidays;
        let totalHoursHoliday = 0;

        if (this.holiday !== undefined) {
          lodash.forEach(this.holiday, (hours, index) => {
            const day = Number(index);

            if (day === 5) {
              totalHoursHoliday += 5;
            } else {
              totalHoursHoliday += 8;
            }
          });
        }

        lodash.forEach(response.data.operator, (operator, key) => {
          this.operatorsPlanings[key] = operator;
          this.operatorsPlanings[key].totalTime = operator.totalTime / 100;
          this.operatorsPlanings[key].totalTime -= totalHoursHoliday;
        });
      }).catch((err: AxiosError) => {
        console.log(err);
      });
    },
    setDaysRouting() {
      const dayRouting = {} as any;
      this.operators.forEach((operator) => {
        this.definedTime[operator.User] = { totalTime: 0 };
        dayRouting[operator.User] = [];
        for (let i = 0; i < 5; i += 1) {
          dayRouting[operator.User].push([]);
        }
      });

      this.routingsAvailable.forEach((routing) => {
        routing.routingsWeekPlanning.forEach((weekRouting) => {
          const testDate = new Date(weekRouting.date);
          testDate.setHours(0, 0, 0, 0);
          this.colDate.forEach((colDate: any, index: any) => {
            if (colDate.getTime() === testDate.getTime()) {
              weekRouting.routingsDayPlanning.forEach((daysRouting) => {
                if (dayRouting[daysRouting.operator.User] !== undefined) {
                  dayRouting[daysRouting.operator.User][index].push(daysRouting);
                  this.definedTime[daysRouting.operator.User].totalTime += daysRouting.minutes;

                  if (this.definedTime[daysRouting.operator.User][weekRouting.baseRouting.getCostCenterName()] === undefined) {
                    this.definedTime[daysRouting.operator.User][weekRouting.baseRouting.getCostCenterName()] = 0;
                  }
                  this.definedTime[daysRouting.operator.User][weekRouting.baseRouting.getCostCenterName()] += daysRouting.minutes;
                }
              });
            }
          });
        });
      });

      this.daysRouting = dayRouting;
    },
    setMouseHover(col: number, row: string) {
      this.colHover = col;
      this.rowHover = row;
    },
    resetMouseHover() {
      this.colHover = undefined;
      this.rowHover = undefined;
    },
    formatHeaderDate(i: number) {
      const newDate = moment(this.startDate)
        .add(i - 1, 'days');
      return newDate.format('DD/MM');
    },
    getLabel(weekRouting: RoutingWeekPlanning) {
      let label = `${TimeHelper.formattingMinuteToHoursMinute(weekRouting.minutes)}h`;
      if (weekRouting.baseRouting instanceof RankRouting) {
        label += ` - ${weekRouting.baseRouting.Rank?.Part}`;
      } else {
        label += ` - ${(weekRouting.baseRouting as GroupedRouting).getGroup()}`;
      }
      return label;
    },
    getBackgroundColor(routing: BaseRouting) {
      return CostCenterHelper.getBackgroundColorFromGroup(
        routing instanceof RankRouting ? routing.CostCenterObject?.Group ?? ''
          : (routing as GroupedRouting).getGroup() ?? '',
      );
    },
    openDetailsWeekRouting(weekRouting: RoutingWeekPlanning) {
      if (weekRouting.internalId !== undefined) {
        routingWeekPanningEvent.trigger('showDetail', weekRouting);
      }
    },
    deleteDayRouting(dayRouting: RoutingDayPlanning, operator: string, i: number) {
      const weekRouting: RoutingWeekPlanning = dayRouting.weekPlanning;
      const weekRoutings = weekRouting.baseRouting.routingsWeekPlanning;
      const index = weekRoutings.indexOf(weekRouting);

      if (index === -1) return;
      Notiflix.Confirm.show(
        'Suppression',
        'Voulez vous supprimer cette programmation ?',
        'Oui',
        'Non',
        () => {
          routingWeekPanningEvent.trigger('closeDetail', weekRouting);
          this.definedTime[operator].totalTime -= dayRouting.minutes;
          lodash.pull(this.daysRouting[operator][i], dayRouting);
          weekRoutings.splice(index, 1);
          weekRouting.remove();
        },
      );
    },
    editDayRouting(dayRouting: RoutingDayPlanning, operator: UserEntity, i: number, noAskMax = false) {
      const weekRouting: RoutingWeekPlanning = dayRouting.weekPlanning;
      const routing = weekRouting.baseRouting;
      const oldMinute = weekRouting.minutes;
      this.taskMinutesEdit = oldMinute;

      const nextMax = Math.ceil(((routing instanceof RankRouting
        ? TimeHelper.fixFormatHoursMinuteNumber(routing.getManuTimeWithCoef())
        : routing.ManuTime)
          - ((routing.getCurrentWeekMinutesDefined() - weekRouting.minutes) / 60)
      )
        * 100) / 100;

      if (!noAskMax) {
        this.costCenterAddTask = undefined;
        const timeNow = parseFloat(TimeHelper.formattingMinuteToHoursMinute(oldMinute));
        this.askMinutes(timeNow, nextMax, operator.User, i, (value) => {
          const minutes = TimeHelper.formattingHoursMinuteToMinute(value);
          // eslint-disable-next-line no-param-reassign
          weekRouting.minutes = minutes;
          weekRouting.save();

          // eslint-disable-next-line no-param-reassign
          dayRouting.minutes = minutes;
          dayRouting.save();
          this.definedTime[operator.User].totalTime += (minutes - oldMinute);
          this.taskMinutesEdit = undefined;
        });
      } else {
        const minutes = TimeHelper.formattingHoursMinuteToMinute(nextMax);
        // eslint-disable-next-line no-param-reassign
        weekRouting.minutes = minutes;
        weekRouting.save();

        // eslint-disable-next-line no-param-reassign
        dayRouting.minutes = minutes;
        dayRouting.save();
        this.definedTime[operator.User].totalTime += (minutes - oldMinute);
      }
    },
    askMinutes(value: number, max: number, operator: string, i: number, clb: (value: number) => void) {
      const remainTimeDay = this.getRemainTimeDayByOperator(operator, i); // sort en minute
      const remainTimeCostCenter = this.getRemainTimeCostCenterByOperator(operator); // sort en minute ou undefined
      const remainTimeWeek = this.getRemainTimeWeekByOperator(operator); // sort en minute

      Notiflix.Confirm.show(
        "Combien d'heures ?",
        `<h2>Pour combien d'heures voulez-vous attribuer cette tâche ?
        <br>${this.getRemainTimeDayText(remainTimeDay)}
        <br>(Temps max tache: ${TimeHelper.fixFormatHoursMinute(max)}h)</h2>`
        + `<input id="askInput" type="number" value="${TimeHelper.fixFormatHoursMinuteNumber(value)}" step="0.1" max="${TimeHelper.fixFormatHoursMinuteNumber(max)}" `
        + 'class="w-full border rounded px-1 py-2 mt-3">',
        'Valider',
        'Annuler',
        () => {
          const inputValue = parseFloat((document.getElementById('askInput') as HTMLInputElement).value);
          let inputValueMinutes = TimeHelper.formattingHoursMinuteToMinute(inputValue);

          if (this.taskMinutesEdit !== undefined) {
            inputValueMinutes -= this.taskMinutesEdit;
          }
          const isTotalTimeTaskExceed = !(TimeHelper.formattingHoursMinuteToMinute(inputValue) <= TimeHelper.formattingHoursMinuteToMinute(max));
          const isDayTaskExceed = remainTimeDay - inputValueMinutes < 0;
          const isCostCenterTaskExceed = (remainTimeCostCenter !== undefined && !(remainTimeCostCenter - inputValueMinutes >= 0));
          const isTaskDefine = !(remainTimeWeek === undefined);
          const isWeekTaskExceed = (remainTimeWeek !== undefined && !(remainTimeWeek - inputValueMinutes >= 0));

          const wait = setTimeout(() => {
            if (isTotalTimeTaskExceed
              || isDayTaskExceed
              || isCostCenterTaskExceed
              || isWeekTaskExceed
            ) {
              Notiflix.Confirm.show(
                'Êtes vous sûr ?',
                this.getConstraintNeedConfirm(isTotalTimeTaskExceed, isDayTaskExceed, isCostCenterTaskExceed, isTaskDefine, isWeekTaskExceed),
                'Valider',
                'Annuler',
                () => {
                  clb(inputValue);
                },
                undefined,
                {
                  plainText: false,
                  messageMaxLength: 999999999,
                  titleColor: '#ff5549',
                  cancelButtonBackground: '#ff5549',
                  backOverlay: true,
                  backOverlayColor: 'rgba(255,85,73,0.2)',
                },
              );
            } else {
              clb(Math.min(inputValue, TimeHelper.fixFormatHoursMinuteNumber(max)));
            }
            clearTimeout(wait);
          }, 300 + 1);
        },
        undefined,
        {
          plainText: false,
          messageMaxLength: 999999999,
        },
      );
    },
    onRoutingStartDrag(data: WeeklyPlanningEventBusType) {
      this.currentDragRouting = data.routing;
      this.updateColors();
    },
    updateColors() {
      if (this.currentDragRouting === undefined && !this.groupIsMooving) {
        this.operators.forEach((operator) => {
          for (let i = 0; i < this.nbColumn; i += 1) {
            this.colorCols[operator.User][i] = '';
          }
        });

        return;
      }

      const colDate = this.colDate;

      if (this.currentDragRouting === undefined && this.groupIsMooving) {
        this.operators.forEach((operator) => {
          for (let i = 0; i < this.nbColumn; i += 1) {
            const testDate = colDate[i];

            let colAllowed = true;
            let isHoliday = false;
            let dayAbsence = false;
            let isWeekTaskDefine = true;
            let isCostCenterDefine = false;
            const totalTimeCostCenterAsigned: any = {};

            // Jour ferier
            if (this.holiday !== undefined && this.holiday[i + 1] !== undefined) {
              isHoliday = this.holiday[i + 1];
            }

            // Jour absence
            if (this.operatorsPlanings[operator.User] === undefined) {
              isWeekTaskDefine = false;
            } else {
              if (this.operatorsPlanings[operator.User].dayAbsence !== undefined
                && this.operatorsPlanings[operator.User].dayAbsence[i + 1] !== undefined
              ) {
                if (i + 1 === 5 && this.operatorsPlanings[operator.User].dayAbsence[i + 1] >= 5) {
                  dayAbsence = true;
                } else if (this.operatorsPlanings[operator.User].dayAbsence[i + 1] >= 8) {
                  dayAbsence = true;
                }
              }

              if (this.operatorsPlanings[operator.User].totalTime !== undefined
                && this.operatorsPlanings[operator.User].totalTime <= 0) {
                dayAbsence = true;
              }
            }

            this.group.forEach((p) => {
              if (!colAllowed) return;

              // cost center
              if (
                this.operatorsPlanings[operator.User] !== undefined
                && this.operatorsPlanings[operator.User].costCenter !== undefined
                && this.operatorsPlanings[operator.User].costCenter[p.routing.getCostCenterName()] !== undefined
                && !isCostCenterDefine
              ) {
                isCostCenterDefine = true;

                if (totalTimeCostCenterAsigned[p.routing.getCostCenterName()] === undefined) {
                  const costCenterTimeDefine = (this.operatorsPlanings[operator.User].costCenter[p.routing.getCostCenterName()] * 60);

                  totalTimeCostCenterAsigned[p.routing.getCostCenterName()] = {
                    timePlanned: costCenterTimeDefine,
                    defineTime: 0,
                  };
                }
                totalTimeCostCenterAsigned[p.routing.getCostCenterName()].defineTime += this.definedTime[operator.User][p.routing.getCostCenterName()];
              }

              const startDate = new Date(p.routing.getStartDate());
              startDate.setHours(0, 0, 0, 0);
              const endDate = new Date(p.routing.getEndDate());
              endDate.setHours(23, 59, 59, 0);
              if (!DateHelper.isDateInRange(
                testDate,
                startDate,
                endDate,
              )) {
                colAllowed = false;
              }
            });

            // CostCenter Max time asignied
            lodash.forEach(totalTimeCostCenterAsigned, (value) => {
              if (value.timePlanned <= value.defineTime) {
                isCostCenterDefine = false;
              }
            });

            let colorCase = '';

            if (colAllowed
              && !isHoliday
              && !dayAbsence
              && isWeekTaskDefine
            ) {
              if (isCostCenterDefine) {
                colorCase = 'bg-green-';
              } else {
                colorCase = 'bg-gray-';
              }
            } else {
              colorCase = 'bg-red-';
            }

            if (i === this.colHover && this.rowHover === operator.User) {
              colorCase += '400 animate-pulse';
            } else {
              colorCase += '200';
            }

            this.colorCols[operator.User][i] = colorCase;
          }
        });
        return;
      }

      if (this.currentDragRouting === undefined) {
        return;
      }

      this.operators.forEach((operator) => {
        for (let i = 0; i < this.nbColumn; i += 1) {
          if (this.currentDragRouting === undefined) {
            return;
          }

          let isHoliday = false;
          let dayAbsence = false;
          let isWeekTaskDefine = true;
          let isCostCenterDefine = false;

          // Jour ferier
          if (this.holiday !== undefined && this.holiday[i + 1] !== undefined) {
            isHoliday = this.holiday[i + 1];
          }

          // Jour absence
          if (this.operatorsPlanings[operator.User] === undefined) {
            isWeekTaskDefine = false;
          } else {
            if (this.operatorsPlanings[operator.User].dayAbsence !== undefined
              && this.operatorsPlanings[operator.User].dayAbsence[i + 1] !== undefined
            ) {
              if (i + 1 === 5 && this.operatorsPlanings[operator.User].dayAbsence[i + 1] >= 5) {
                dayAbsence = true;
              } else if (this.operatorsPlanings[operator.User].dayAbsence[i + 1] >= 8) {
                dayAbsence = true;
              }
            }

            if (this.operatorsPlanings[operator.User].totalTime !== undefined
              && this.operatorsPlanings[operator.User].totalTime <= 0) {
              dayAbsence = true;
            }

            // cost center
            if (this.operatorsPlanings[operator.User].costCenter !== undefined
              && this.operatorsPlanings[operator.User].costCenter[this.currentDragRouting.getCostCenterName()] !== undefined
            ) {
              isCostCenterDefine = true;

              const costCenterTimeDefine = (this.operatorsPlanings[operator.User].costCenter[this.currentDragRouting.getCostCenterName()] * 60);
              if (costCenterTimeDefine <= this.definedTime[operator.User][this.currentDragRouting.getCostCenterName()]) {
                isCostCenterDefine = false;
              }
            }
          }

          const testDate = colDate[i];
          const startDate = new Date(this.currentDragRouting.getStartDate());
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(this.currentDragRouting.getEndDate());
          endDate.setHours(23, 59, 59, 0);

          let colorCase = '';

          if (DateHelper.isDateInRange(
            testDate,
            startDate,
            endDate,
          )
            && !isHoliday
            && !dayAbsence
            && isWeekTaskDefine
          ) {
            if (isCostCenterDefine) {
              colorCase = 'bg-green-';
            } else {
              colorCase = 'bg-gray-';
            }
          } else {
            colorCase = 'bg-red-';
          }

          if (i === this.colHover && this.rowHover === operator.User) {
            colorCase += '400 animate-pulse';
          } else {
            colorCase += '200';
          }

          this.colorCols[operator.User][i] = colorCase;
        }
      });
    },
    onRoutingGroupStopDrag() {
      this.updateColors();

      const colHover = this.colHover;
      // Guard : Si sur une case
      if (colHover === undefined || this.rowHover === undefined) return;
      const dayColDate = this.colDate[colHover];
      const operator: UserEntity | undefined = UserHelper.getUserById(this.rowHover);

      if (operator === undefined) return;

      let isHoliday = false;
      let dayAbsence = false;
      let isCostCenterDefine = false;
      const totalTimeCostCenterAsigned: any = {};

      // Jour ferier
      if (this.holiday !== undefined && this.holiday[colHover] !== undefined) {
        isHoliday = this.holiday[colHover];
      }

      // Jour absence
      if (this.operatorsPlanings[operator.User] !== undefined) {
        if (this.operatorsPlanings[operator.User].dayAbsence !== undefined
          && this.operatorsPlanings[operator.User].dayAbsence[colHover] !== undefined
        ) {
          if (colHover === 5 && this.operatorsPlanings[operator.User].dayAbsence[colHover] >= 5) {
            dayAbsence = true;
          } else if (this.operatorsPlanings[operator.User].dayAbsence[colHover] >= 8) {
            dayAbsence = true;
          }
        }

        if (this.operatorsPlanings[operator.User].totalTime !== undefined
          && this.operatorsPlanings[operator.User].totalTime <= 0) {
          dayAbsence = true;
        }
      }

      let allowed = true;
      this.group.forEach((p) => {
        if (!allowed) return;
        const startDate = new Date(p.routing.getStartDate());
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(p.routing.getEndDate());
        endDate.setHours(23, 59, 59, 0);

        if (!DateHelper.isDateInRange(dayColDate, startDate, endDate)) {
          allowed = false;
        }

        // cost center
        if (this.operatorsPlanings[operator.User] !== undefined
          && this.operatorsPlanings[operator.User].costCenter !== undefined
          && this.operatorsPlanings[operator.User].costCenter[p.routing.getCostCenterName()] !== undefined
          && !isCostCenterDefine
        ) {
          isCostCenterDefine = true;

          if (totalTimeCostCenterAsigned[p.routing.getCostCenterName()] === undefined) {
            const costCenterTimeDefine = (this.operatorsPlanings[operator.User].costCenter[p.routing.getCostCenterName()] * 60);

            totalTimeCostCenterAsigned[p.routing.getCostCenterName()] = {
              timePlanned: costCenterTimeDefine,
              defineTime: 0,
            };
          }
          totalTimeCostCenterAsigned[p.routing.getCostCenterName()].defineTime += this.definedTime[operator.User][p.routing.getCostCenterName()];
        }
      });

      // CostCenter Max time asignied
      lodash.forEach(totalTimeCostCenterAsigned, (value) => {
        if (value.timePlanned <= value.defineTime) {
          isCostCenterDefine = false;
        }
      });

      // Guard: Si sur une case verte
      if (!allowed) {
        return;
      }

      if (isHoliday || dayAbsence) {
        Notiflix.Notify.failure("L'opérateur n'est pas disponible pour ce jour.");
        return;
      }

      this.group.forEach((p) => {
        const routing = p.routing as BaseRouting;
        let weekRouting: undefined | RoutingWeekPlanning = routing
          .routingsWeekPlanning.find((wr) => wr.date.getTime() === dayColDate.getTime()) as
          undefined | RoutingWeekPlanning;

        if (weekRouting !== undefined) {
          const dayRouting = weekRouting.routingsDayPlanning.find((dr) => (dr.operator.User === operator.User && dr.startDate.getDay() === dayColDate.getDay()));
          if (dayRouting !== undefined) {
            this.editDayRouting(dayRouting, operator, colHover);
            return;
          }
        }

        const nextMax = Math.ceil(((routing instanceof RankRouting
          ? TimeHelper.fixFormatHoursMinuteNumber(routing.getManuTimeWithCoef())
          : routing.ManuTime)
          - routing.getCurrentWeekMinutesDefined() / 60) * 100) / 100;

        const minutes = TimeHelper.formattingHoursMinuteToMinute(nextMax);

        weekRouting = new RoutingWeekPlanning(routing as BaseRouting, false);
        weekRouting.minutes = minutes;
        weekRouting.date = dayColDate;

        weekRouting.save(() => {
          routing.routingsWeekPlanning.push(weekRouting as RoutingWeekPlanning);
          if (weekRouting !== undefined) {
            const dayPlanning = this.createRouteDayPlanning(weekRouting, operator, dayColDate, minutes);
            dayPlanning.save(() => {
              if (weekRouting !== undefined) {
                weekRouting.routingsDayPlanning.push(dayPlanning);
                this.setDaysRouting();
                this.stopReplanningPoste(routing);
              }
            });
          }
        });
      });

      HebdoGroupStore().group.splice(0);
    },
    onRoutingStopDrag(data: WeeklyPlanningEventBusType) {
      this.currentDragRouting = undefined;
      this.updateColors();
      const colHover = this.colHover;

      // Guard : Si sur une case
      if (colHover === undefined || this.rowHover === undefined) return;
      const operator: UserEntity | undefined = UserHelper.getUserById(this.rowHover);
      if (operator === undefined) return;

      let isHoliday = false;
      let dayAbsence = false;

      // Jour ferier
      if (this.holiday !== undefined && this.holiday[colHover + 1] !== undefined) {
        isHoliday = this.holiday[colHover + 1];
      }

      // Jour absence
      if (this.operatorsPlanings[operator.User] !== undefined) {
        if (this.operatorsPlanings[operator.User].dayAbsence !== undefined
          && this.operatorsPlanings[operator.User].dayAbsence[colHover + 1] !== undefined
        ) {
          if (colHover === 5 && this.operatorsPlanings[operator.User].dayAbsence[colHover + 1] >= 5) {
            dayAbsence = true;
          } else if (this.operatorsPlanings[operator.User].dayAbsence[colHover + 1] >= 8) {
            dayAbsence = true;
          }
        }

        if (this.operatorsPlanings[operator.User].totalTime !== undefined
          && this.operatorsPlanings[operator.User].totalTime <= 0) {
          dayAbsence = true;
        }
      }

      const routing = data.routing as BaseRouting;
      const testDate = this.colDate[colHover];
      const startDate = new Date(routing.getStartDate());
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(routing.getEndDate());
      endDate.setHours(23, 59, 59, 0);

      // Guard: Si sur une case verte
      if (!DateHelper.isDateInRange(testDate, startDate, endDate)) {
        return;
      }

      if (isHoliday || dayAbsence) {
        Notiflix.Notify.failure("L'opérateur n'est pas disponible cette journée.");
        return;
      }

      let weekRouting: undefined | RoutingWeekPlanning = routing
        .routingsWeekPlanning.find((wr) => wr.date.getTime() === testDate.getTime());

      if (weekRouting !== undefined) {
        const dayRouting = weekRouting.routingsDayPlanning.find((dr) => (dr.operator.User === operator.User && dr.startDate.getDay() === testDate.getDay()));
        if (dayRouting !== undefined) {
          this.editDayRouting(dayRouting, operator, colHover);
          return;
        }
      }
      this.costCenterAddTask = routing.getCostCenterName();

      const nextMax = Math.ceil(((routing instanceof RankRouting
        ? TimeHelper.fixFormatHoursMinuteNumber(routing.getManuTimeWithCoef())
        : routing.ManuTime)
        - routing.getCurrentWeekMinutesDefined() / 60) * 100) / 100;

      this.askMinutes(nextMax, nextMax, operator.User, colHover, (value) => {
        const minutes = TimeHelper.formattingHoursMinuteToMinute(value);
        weekRouting = new RoutingWeekPlanning(routing, false);
        weekRouting.minutes = minutes;
        weekRouting.date = testDate;
        weekRouting.save(() => {
          routing.routingsWeekPlanning.push(weekRouting as RoutingWeekPlanning);
          if (weekRouting !== undefined) {
            const dayPlanning = this.createRouteDayPlanning(weekRouting, operator, testDate, minutes);
            dayPlanning.save(() => {
              if (weekRouting !== undefined) {
                weekRouting.routingsDayPlanning.push(dayPlanning);
                this.setDaysRouting();
                this.costCenterAddTask = undefined;
                this.stopReplanningPoste(routing);
              }
            });
          }
        });
      });
    },
    stopReplanningPoste(routing: BaseRouting) {
      if (routing.isReplanned) {
        if (TimeHelper.formattingHoursMinuteToMinute(routing.ManuTime) <= routing.getCurrentWeekMinutesDefined()) {
          routing.setIsReplanned(false);
        }
      }
    },
    createRouteDayPlanning(routeWeekPlanning: RoutingWeekPlanning, operator: UserEntity, dayToAdd: Date, minutes: number): RoutingDayPlanning {
      const newDate = moment(dayToAdd, 'HH:mm').hours(8);
      const startDate = clone(dayToAdd);
      const hoursSplit = newDate;
      startDate.setHours(hoursSplit.hours(), hoursSplit.minute(), 0, 0);

      const dayPlanning = new RoutingDayPlanning(
        routeWeekPlanning,
        operator,
        startDate,
      );

      dayPlanning.minutes = minutes;
      dayPlanning.totalTimeClocking = 0;
      dayPlanning.startDateClocking = undefined;
      dayPlanning.isFinished = false;
      dayPlanning.isNotFinishReplaned = false;

      return dayPlanning;
    },
    getRemainTimeDayByOperator(operator: string, i: number): number {
      return (this.totalTimeAvailableByDay(operator, (i)) * 60) - (this.totalTimeByDay(operator, (i)));
    },
    getRemainTimeDayText(remainTimeDay: number) {
      let infosRemaining = `(Temps opérateur restant ${TimeHelper.formattingMinuteToHoursMinute(remainTimeDay)}h / jour)`;

      if (remainTimeDay < 0) {
        infosRemaining = '(Temps jour opérateur dépassé)';
      }

      return infosRemaining;
    },
    getRemainTimeCostCenterByOperator(operator: string): number | undefined {
      if (this.costCenterAddTask === undefined) {
        return undefined;
      }

      if (this.operatorsPlanings[operator] === undefined) {
        return 0;
      }

      if (this.operatorsPlanings[operator].costCenter !== undefined
        && this.operatorsPlanings[operator].costCenter[this.costCenterAddTask] !== undefined
      ) {
        const costCenterTimeDefine = (this.operatorsPlanings[operator].costCenter[this.costCenterAddTask] * 60);
        const timeCostCenterAsigned = this.definedTime[operator][this.costCenterAddTask] === undefined ? 0 : this.definedTime[operator][this.costCenterAddTask];

        return costCenterTimeDefine - timeCostCenterAsigned;
      }

      return 0;
    },
    getRemainTimeWeekByOperator(operator: string): number | undefined {
      if (this.operatorsPlanings[operator] === undefined) {
        return undefined;
      }

      const totalTimeDefineToUser = this.operatorsPlanings[operator].totalTime * 60;
      const defineTime = this.definedTime[operator].totalTime;

      return totalTimeDefineToUser - defineTime;
    },
    getConstraintNeedConfirm(
      isTotalTimeTaskExceed: boolean,
      isDayTaskExceed: boolean,
      isCostCenterTaskExceed: boolean,
      isTaskDefine: boolean,
      isWeekTaskExceed: boolean,
    )
      : string {
      let constraintConfirm = '';

      if (isTotalTimeTaskExceed) {
        constraintConfirm += 'Temps tâches affecté dépassé !<br>';
      }

      if (isWeekTaskExceed && isTaskDefine) {
        constraintConfirm += 'Temps semaine disponible dépasser !<br>';
      } else if (!isTaskDefine) {
        constraintConfirm += 'Opérateur sans planification !<br>';
        return constraintConfirm;
      }

      if (isDayTaskExceed) {
        constraintConfirm += 'Temps journée disponible dépasser !<br>';
      }

      if (isCostCenterTaskExceed) {
        constraintConfirm += 'Temps centre de frais disponible dépassé<br>';
      }

      return constraintConfirm;
    },
  },
  mounted() {
    WeeklyPlanningMoveEvent.on('afterMouseDown', this.onRoutingStartDrag);
    WeeklyPlanningMoveEvent.on('afterMouseUp', this.onRoutingStopDrag);

    // Group
    WeeklyPlanningMoveEvent.on('afterGroupMouseDown', this.updateColors);
    WeeklyPlanningMoveEvent.on('afterGroupMouseUp', this.onRoutingGroupStopDrag);
  },
  unmounted() {
    WeeklyPlanningMoveEvent.off('afterMouseDown', this.onRoutingStartDrag);
    WeeklyPlanningMoveEvent.off('afterMouseUp', this.onRoutingGroupStopDrag);

    // Group
    WeeklyPlanningMoveEvent.off('afterGroupMouseDown', this.updateColors);
    WeeklyPlanningMoveEvent.off('afterGroupMouseUp', this.onRoutingGroupStopDrag);
  },
  created() {
    this.setDaysRouting();
    this.setOperator();
    this.operators.forEach((operator) => {
      this.colorCols[operator.User] = [];
      for (let i = 0; i < this.nbColumn; i += 1) {
        this.colorCols[operator.User].push('');
      }
    });
  },
  watch: {
    colHover() {
      this.updateColors();
    },
    startDate() {
      this.setOperator();
      this.setDaysRouting();
    },
    routingsAvailable() {
      this.setDaysRouting();
    },
  },
});
