import { defineStore } from 'pinia';
import BaseRouting from '@/class/BaseRouting';

const HebdoGroupStore = defineStore('hebdoGroupStore', {
  state: () => ({
    group: new Array<{routing: BaseRouting, ref: HTMLDivElement}>(),
    groupIsMooving: false,
  }),
});

export default HebdoGroupStore;
