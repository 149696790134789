
import { defineComponent } from 'vue';
import AffaireRank from '@/class/AffaireRank';
import { DatePicker } from 'v-calendar';
import RankRouting from '@/class/RankRouting';
import MainEndpoint from '@/endpoints/main';
import BomHelper from '@/helper/BomHelper';
import BomStatut from '@/enum/BomStatut';
import GroupedRouting from '@/class/GroupedRouting';
import CostCenter from '@/class/CostCenter';
import CostCenterHelper from '@/helper/CostCenterHelper';
import Bom from '@/class/Bom';
import planificateurJobBus from '@/bus/planificateurJobBus';

export default defineComponent({
  name: 'DetailTask',
  components: { DatePicker },
  data() {
    return {
      lastUpdate: Date.now(),
      comment: '',
      lastType: Date.now(),
      commentSent: true,
      mouseX: 0,
      mouseY: 0,
    };
  },
  props: {
    task: {
      type: AffaireRank,
      required: true,
    },
  },
  computed: {
    delaiInterneRegroupe() {
      const internalGroupDelay = new Array<{poste: GroupedRouting, date: string}>();
      this.task.Postes.forEach((poste) => {
        const costCenter = poste.CostCenterObject;
        if (costCenter === undefined) return;
        if (costCenter.Group === undefined) return;

        const regroupGroup = Object.keys(CostCenter.regroupedGroupId);
        if (!regroupGroup.includes(costCenter.Group)) return;
        // Ce cost center à un groupe donc on vient chercher sa date
        const rr = this.task.affaire.regroupedRoutings.find((rrc) => rrc.getGroup() === costCenter.Group);
        if (rr === undefined) return;

        // Pas de doublage
        if (internalGroupDelay.find((v) => v.poste.getGroup() === rr.getGroup()) !== undefined) return;
        internalGroupDelay.push({
          poste: rr,
          date: rr.getEndDate().toLocaleDateString(),
        });
      });

      return internalGroupDelay;
    },
    hasAtLeastOneBom(): boolean {
      return this.task.Postes.filter((p) => p.getBomsToShow().length > 0).length > 0;
    },
  },
  methods: {
    getBackgroundColor(group: string) {
      return CostCenterHelper.getBackgroundColorFromGroup(group);
    },
    mousepostion(event: MouseEvent) {
      this.mouseX = event.pageX + 5;
      this.mouseY = event.pageY + 5;
    },
    dateUpdated(routing: RankRouting) {
      const now = Date.now();
      if (now - this.lastUpdate === 0) {
        return;
      }
      this.lastUpdate = now;
      setTimeout(() => {
        routing.persistDate();
      }, 0);
    },
    getBackgroundColorStatusBom(statut: BomStatut) {
      return BomHelper.getBackgroundStatusColor(statut);
    },
    getLastStatus(bom: Bom) {
      return bom.getStatut()[bom.getStatut().length - 1];
    },
    commentUpdate() {
      this.commentSent = false;
      this.lastType = Date.now();
      const newComment = (this.$refs.commentTextArea as HTMLTextAreaElement).value;
      // eslint-disable-next-line vue/no-mutating-props
      this.task.comment = newComment;
      setTimeout(() => {
        if (Date.now() - this.lastType < 1000) {
          return;
        }
        MainEndpoint.getAxiosInstance().post(`/rank/${this.task.IdJobFile}/update-comment`, {
          comment: newComment,
        }).then(() => {
          this.commentSent = true;
        });
      }, 1000);
    },
    getAffaireLiee(bom: Bom) {
      return bom.ProductionReservations.map((x) => x.Job).join(', ');
    },
    loadAffaireLiee(bom: Bom) {
      bom.ProductionReservations.forEach((x) => planificateurJobBus.trigger('loadJob', x.Job));
    },
  },
});
