

import { defineComponent, PropType } from 'vue';
import GuiH1 from '@/components/gui/H1.vue';
import AffaireBase from '@/class/AffaireBase';
import { Grid, h } from 'gridjs';
import 'gridjs/dist/theme/mermaid.css';

export default defineComponent({
  name: 'ResultatAffaires',
  components: {
    GuiH1,
  },
  props: {
    searchAffaires: {
      type: Object as PropType<Array<AffaireBase>>,
      required: true,
    },
  },
  data() {
    return {
      grid: undefined as undefined | Grid,
    };
  },

  computed: {
    dataTable(): Array<Array<string>> {
      const finalResult = new Array<Array<string>>();
      this.searchAffaires.forEach((affaire) => {
        const result = new Array<string>();
        result.push(String(affaire.Job));
        result.push(affaire.Designation1);
        result.push(affaire.Order);
        result.push(affaire.InternalOrder);
        result.push(affaire.CustomerCode);
        result.push('<button>Coucou</button>');
        finalResult.push(result);
      });
      return finalResult;
    },
  },
  watch: {
    searchAffaires() {
      if (this.grid !== undefined) {
        this.grid.updateConfig({
          data: this.dataTable,
        }).forceRender();
      }
    },
  },
  mounted() {
    this.grid = new Grid({
      pagination: {
        enabled: true,
        limit: 10,
        summary: false,
      },
      language: {
        pagination: {
          previous: 'Précédent',
          next: 'Suivant',
        },
        noRecordsFound: 'Aucun résultat',
      },
      columns: [
        'JobID',
        'Designation',
        'Customer Order',
        'Internal Order',
        'Customer Code',
        {
          name: 'Actions',
          formatter: (cell, row) => h('button', {
            className: 'py-2 mb-4 px-4 border rounded-md text-white bg-blue-600 hover:bg-blue-700 transition',
            onClick: () => this.$emit('searchById', row.cells[0].data),
          }, 'Ajouter à l\'affichage'),
        }],
      data: this.dataTable,
    });
    this.grid.render(this.$refs.wrapper as Element);
  },
});
