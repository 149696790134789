
import { defineComponent } from 'vue';
import planificateurEventBus from '@/bus/planificateurEventBus';
import DateHelper from '@/helper/DateHelper';
import RankHelper from '@/helper/RankHelper';
import CostCenter from '@/class/CostCenter';
import GroupedRouting from '@/class/GroupedRouting';
import CostCenterHelper from '@/helper/CostCenterHelper';
import AffaireRank from '../../class/AffaireRank';

export default defineComponent({
  name: 'LineDetail',
  props: {
    value: {
      type: AffaireRank,
      required: true,
    },
    parentStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    delaiInterne() {
      let maxDate = undefined as undefined | Date;
      this.value.getRoutingsToShow().forEach((p) => {
        maxDate = DateHelper.max(p.endDate, maxDate ?? p.endDate);
      });
      return maxDate !== undefined ? maxDate.toLocaleDateString() : undefined;
    },
    delaiInterneRegroupe() {
      const internalGroupDelay = new Array<{poste: GroupedRouting, date: string}>();
      this.value.Postes.forEach((poste) => {
        const costCenter = poste.CostCenterObject;
        if (costCenter === undefined) return;
        if (costCenter.Group === undefined) return;

        const regroupGroup = Object.keys(CostCenter.regroupedGroupId);
        if (!regroupGroup.includes(costCenter.Group)) return;
        // Ce cost center a un group donc on vient chercher sa date
        const rr = this.value.affaire.regroupedRoutings.find((rrc) => rrc.getGroup() === costCenter.Group);
        if (rr === undefined) return;

        // Pas de doublage
        if (internalGroupDelay.find((v) => v.poste.getGroup() === rr.getGroup()) !== undefined) return;
        internalGroupDelay.push({
          poste: rr,
          date: rr.getEndDate().toLocaleDateString(),
        });
      });

      return internalGroupDelay;
    },
  },
  methods: {
    getBackgroundColor(group: string) {
      return CostCenterHelper.getBackgroundColorFromGroup(group);
    },
    showDetails() {
      planificateurEventBus.trigger('showDetail', this.value);
    },
    getColorLevel(level: number) {
      return RankHelper.getColorLevel(level);
    },
  },
});

