

import { defineComponent } from 'vue';
import CostCenterHelper from '@/helper/CostCenterHelper';
import { mapState } from 'pinia';
import GanttStore from '@/store/GanttStore';

export default defineComponent({
  name: 'CostCenterSidebar',

  computed: {
    costCentersGroup() {
      return CostCenterHelper.getCostCenterToShowGroups();
    },
    ...mapState(GanttStore, ['costCenterGroupFilter']),
  },
  methods: {
    textColor() {
      return {
        'text-white': true,
      };
    },
    textBadgeColor(group: string) {
      return {
        [CostCenterHelper.getBackgroundColorFromGroup(group)]: true,
      };
    },
    isGroupFiltered(group: string) {
      return this.costCenterGroupFilter.includes(group);
    },
    toggleFilterCostCenter(group: string) {
      if (this.isGroupFiltered(group)) {
        this.costCenterGroupFilter.splice(this.costCenterGroupFilter.indexOf(group), 1);
      } else {
        this.costCenterGroupFilter.push(group);
      }
    },
  },
});
