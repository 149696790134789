import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_h1 = _resolveComponent("gui-h1")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_gui_h1, { class: "border-b mb-2" }, {
      default: _withCtx(() => [
        _createTextVNode("Résulat de la recherche")
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, null, 512)
  ]))
}